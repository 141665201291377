<template>
  <div class="footer-wrapper">
    <footer id="main-footer" class="main-footer">
      <div class="elementor elementor-1273 elementor-location-footer fooo" data-elementor-id="1273"
           data-elementor-post-type="elementor_library" data-elementor-type="footer">
        <section       style="background-color: #242627 !important;"
                       class="elementor-section elementor-top-section elementor-element elementor-element-5c24017 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5c24017" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
          <div class="elementor-container elementor-column-gap-no">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aa9e495" data-id="aa9e495" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-1c581fa elementor-widget elementor-widget-heading" data-id="1c581fa" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container fooo">
                    <h4 class="elementor-heading-title elementor-size-default">Solutions</h4>
                  </div>
                </div>
                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-e079cb5 elementor-widget__width-initial elementor-nav-menu--dropdown-none vamtam-has-mobile-disable-scroll elementor-widget elementor-widget-nav-menu" data-id="e079cb5" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;svg class=\&quot;e-font-icon-svg e-fas-caret-down\&quot; viewBox=\&quot;0 0 320 512\&quot; xmlns=\&quot;http:\/\/www.w3.org\/2000\/svg\&quot;&gt;&lt;path d=\&quot;M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\&quot;&gt;&lt;\/path&gt;&lt;\/svg&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                  <div class="elementor-widget-container">
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                      <ul id="menu-1-e079cb5" class="elementor-nav-menu sm-vertical"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987986"><a href="/document-and-media-storage" class="elementor-item">Document &#038; Media Storage</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987988"><a href="/data-entry" class="elementor-item">Data Entry</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987989"><a href="/digital-archiving" class="elementor-item">Digital Archiving</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987990"><a href="/secure-destruction" class="elementor-item">Secure Destruction</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-1e832ec elementor-widget__width-initial elementor-nav-menu--dropdown-none vamtam-has-mobile-disable-scroll elementor-widget elementor-widget-nav-menu" data-id="1e832ec" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;svg class=\&quot;e-font-icon-svg e-fas-caret-down\&quot; viewBox=\&quot;0 0 320 512\&quot; xmlns=\&quot;http:\/\/www.w3.org\/2000\/svg\&quot;&gt;&lt;path d=\&quot;M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\&quot;&gt;&lt;\/path&gt;&lt;\/svg&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                  <div class="elementor-widget-container">
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                      <ul id="menu-1-1e832ec" class="elementor-nav-menu sm-vertical">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987990"><a href="/vault-storage" class="elementor-item" tabindex="-1">Vault Storage</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987990"><a href="/customized-software-solutions" class="elementor-item" tabindex="-1">Customized Solutions</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987991"><a href="/asset-management-services" class="elementor-item">Asset Management</a></li>

                      </ul>			</nav>
                  </div>
                </div>
                <div class="elementor-element elementor-element-34a7fb1 elementor-widget elementor-widget-spacer" data-id="34a7fb1" data-element_type="widget" data-widget_type="spacer.default">
                  <div class="elementor-widget-container">
                    <div class="elementor-spacer">
                      <div class="elementor-spacer-inner"></div>
                    </div>
                  </div>
                </div>
                <div class="elementor-element elementor-element-be5f518 elementor-widget elementor-widget-heading" data-id="be5f518" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">Company</h4>		</div>
                </div>
                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-eca9653 elementor-widget__width-initial elementor-nav-menu--dropdown-none vamtam-has-mobile-disable-scroll elementor-widget elementor-widget-nav-menu" data-id="eca9653" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;svg class=\&quot;e-font-icon-svg e-fas-caret-down\&quot; viewBox=\&quot;0 0 320 512\&quot; xmlns=\&quot;http:\/\/www.w3.org\/2000\/svg\&quot;&gt;&lt;path d=\&quot;M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\&quot;&gt;&lt;\/path&gt;&lt;\/svg&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                  <div class="elementor-widget-container">
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                      <ul id="menu-1-e079cb5" class="elementor-nav-menu sm-vertical">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987987"><a href="/about-us" class="elementor-item">About Us</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-987988"><a href="/facility" class="elementor-item">Facility</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aa9e495" data-id="aa9e495" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-1c581fa elementor-widget elementor-widget-heading" data-id="1c581fa" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container fooo">
                    <h4 class="elementor-heading-title elementor-size-default">Contacts</h4>
                  </div>
                </div>
                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-e079cb5 elementor-widget__width-initial elementor-nav-menu--dropdown-none vamtam-has-mobile-disable-scroll elementor-widget elementor-widget-nav-menu" data-id="e079cb5" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;svg class=\&quot;e-font-icon-svg e-fas-caret-down\&quot; viewBox=\&quot;0 0 320 512\&quot; xmlns=\&quot;http:\/\/www.w3.org\/2000\/svg\&quot;&gt;&lt;path d=\&quot;M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\&quot;&gt;&lt;\/path&gt;&lt;\/svg&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                  <div class="elementor-widget-container">
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                      <ul id="menu-1-e079cb5" class="elementor-nav-menu sm-vertical">
                        <li class="text-[#F5F5F1B3] menu-item menu-item-type-post_type menu-item-object-page menu-item-987986 my-2"  style="margin: 8px 0">Address: 47 Road 250, Maadi, Cairo, Egypt</li>
                        <li class="text-[#F5F5F1B3] menu-item menu-item-type-post_type menu-item-object-page menu-item-987986 my-2"  style="margin: 8px 0">Email: info@eds-egypt.com</li>
                        <li class="text-[#F5F5F1B3] menu-item menu-item-type-post_type menu-item-object-page menu-item-987986 my-2" style="margin: 8px 0">Phones: +20 2 2521 4305/7</li>
                        <li class="text-[#F5F5F1B3] menu-item menu-item-type-post_type menu-item-object-page menu-item-987986 my-2" style="margin: 8px 0">+20 2 2517 5977</li>
                        <li class="text-[#F5F5F1B3] menu-item menu-item-type-post_type menu-item-object-page menu-item-987986 my-2" style="margin: 8px 0">+20 109 785 8008</li>

                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="elementor-element elementor-element-34a7fb1 elementor-widget elementor-widget-spacer" data-id="34a7fb1" data-element_type="widget" data-widget_type="spacer.default">
                  <div class="elementor-widget-container">
                    <div class="elementor-spacer">
                      <div class="elementor-spacer-inner"></div>
                    </div>
                  </div>
                </div>
                <div class="elementor-element elementor-element-be5f518 elementor-widget elementor-widget-heading" data-id="be5f518" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">Social</h4>		</div>
                </div>
                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-eca9653 elementor-widget__width-initial elementor-nav-menu--dropdown-none vamtam-has-mobile-disable-scroll elementor-widget elementor-widget-nav-menu" data-id="eca9653" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;svg class=\&quot;e-font-icon-svg e-fas-caret-down\&quot; viewBox=\&quot;0 0 320 512\&quot; xmlns=\&quot;http:\/\/www.w3.org\/2000\/svg\&quot;&gt;&lt;path d=\&quot;M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\&quot;&gt;&lt;\/path&gt;&lt;\/svg&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                  <div class="elementor-widget-container">
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                      <div

                          class="elementor-column elementor-col-100 elementor-position-left pr-5 elementor-top-column elementor-element elementor-element-562f9e3"
                          data-element_type="column" data-id="562f9e3">
                          <div
                              class="mx-2 vamtam-has-theme-widget-styles elementor-element elementor-element-9290793 elementor-widget__width-auto elementor-widget-mobile__width-initial elementor-view-default elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                              data-element_type="widget" data-id="9290793" data-widget_type="icon-box.default">
                            <div class="elementor-widget-container" >
                              <link  href="../../wp-content/plugins/elementor/assets/css/widget-icon-box.min.css"
                                     rel="stylesheet">
                              <div  class="elementor-icon-box-wrapper">
                                <div  class="elementor-icon-box-icon">
                                  <a class="elementor-icon elementor-animation-"
                                     href="https://www.linkedin.com/company/eds1/" tabindex="-1"
                                     target="_blank">
                                    <svg aria-hidden="true" class="e-font-icon-svg e-fab-linkedin"   style="fill: #F5F5F1B3;"

                                         viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                                    </svg>
                                  </a>
                                </div>
                                <div style="display: flex" class="elementor-icon-box-content">
                                  <h3 class="elementor-icon-box-title text-[#F5F5F1B3]" style="color: #F5F5F1B3 !important; " >
                                    <a  href="https://www.linkedin.com/company/eds1/" target="_blank">
                                      LinkedIn </a>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                              class="mx-2 vamtam-has-theme-widget-styles elementor-element elementor-element-19a4854 elementor-widget__width-auto elementor-widget-mobile__width-initial elementor-view-default elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                              data-element_type="widget" data-id="19a4854" data-widget_type="icon-box.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <a class="elementor-icon elementor-animation-"
                                     href="https://www.facebook.com/Edsinfo/" tabindex="-1"
                                     target="_blank">
                                    <svg aria-hidden="true" class="e-font-icon-svg e-fab-facebook"
                                         viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"   style="fill: #F5F5F1B3;"
                                    >
                                      <path
                                          d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                                    </svg>
                                  </a>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h3 class="elementor-icon-box-title" style="color: #F5F5F1B3 !important; ">
                                    <a href="https://www.facebook.com/Edsinfo/" target="_blank">
                                      Facebook </a>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                              class="mx-2 vamtam-has-theme-widget-styles elementor-element elementor-element-5aa55c3 elementor-widget__width-auto elementor-widget-mobile__width-initial elementor-view-default elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                              data-element_type="widget" data-id="5aa55c3" data-widget_type="icon-box.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-icon-box-wrapper">
                                <div class="elementor-icon-box-icon">
                                  <a class="elementor-icon elementor-animation-"
                                     href="https://api.whatsapp.com/send/?phone=%2B201012219105&amp;text&amp;type=phone_number&amp;app_absent=0"
                                     tabindex="-1" target="_blank">
                                    <svg aria-hidden="true" class="e-font-icon-svg e-fab-whatsapp"   style="fill: #F5F5F1B3;"

                                         viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                                    </svg>
                                  </a>
                                </div>
                                <div class="elementor-icon-box-content">
                                  <h3 class="elementor-icon-box-title" style="color: #F5F5F1B3 !important; ">
                                    <a href="https://api.whatsapp.com/send/?phone=%2B201012219105&amp;text&amp;type=phone_number&amp;app_absent=0"
                                       target="_blank">
                                      WhatsApp
                                    </a>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                    </nav>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9f9047c" data-id="9f9047c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;background_motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;background_motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_scale_direction&quot;:&quot;in-out-in&quot;,&quot;background_motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:2,&quot;sizes&quot;:[]},&quot;background_motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:38,&quot;end&quot;:100}},&quot;background_motion_fx_devices&quot;:[&quot;desktop&quot;]}" data-e-bg-lazyload=".elementor-element-populated">-->
<!--              <div class="elementor-widget-wrap" style="position: relative; top: 60%">-->

<!--                <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-4267c6c elementor-align-center elementor-widget elementor-widget-button" data-id="4267c6c" data-element_type="widget" data-widget_type="button.default">-->
<!--                  <div class="elementor-widget-container contactus">-->
<!--                    <div class="elementor-button-wrapper">-->
<!--                      <a class="elementor-button elementor-button-link elementor-size-sm" href="/contact-us">-->
<!--						<span class="elementor-button-content-wrapper">-->
<!--						<span class="elementor-button-text"  style="padding: 15px">Contact EDS</span>-->
<!--		</span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->

<!--            <div style="position: absolute ; left:  0 ; bottom: 0 ; display: flex " class="">-->
<!--              <img src="../../assets/eds_logo_white.png" loading="lazy" alt="" style="width: 100px ; border-radius: 10px; z-index: 1" />-->
<!--            </div>-->
          </div>

        </section>
<!--        <section-->
<!--             style="background-color: #242627 !important ; border-top: 1px solid rgba(239,239,239,0.11)"-->
<!--            class="elementor-section elementor-top-section elementor-element elementor-element-40daad3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"-->
<!--            data-element_type="section" data-id="40daad3"-->
<!--            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">-->
<!--          <div class="elementor-container elementor-column-gap-no">-->
<!--            <div-->
<!--                class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5686166"-->
<!--                data-element_type="column" data-id="5686166">-->
<!--              <div class="elementor-widget-wrap elementor-element-populated">-->
<!--                <div-->
<!--                    class="elementor-element elementor-element-3708d21 elementor-widget__width-initial elementor-absolute e-transform elementor-hidden-mobile elementor-widget elementor-widget-spacer"-->
<!--                    data-element_type="widget" data-id="3708d21"-->
<!--                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:90,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"-->
<!--                    data-widget_type="spacer.default">-->
<!--                  <div class="elementor-widget-container">-->
<!--                    <div class="elementor-spacer">-->
<!--                      <div class="elementor-spacer-inner bg-[#F5F5F1B3]"></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div-->
<!--                    class="elementor-element elementor-element-259f990 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-text-editor"-->
<!--                    data-element_type="widget" data-id="259f990"-->
<!--                    data-widget_type="text-editor.default">-->
<!--                  <div class="elementor-widget-container">-->
<!--                    <p class="text-[#F5F5F1B3]">Address: 47 Road 250, Maadi, Cairo, Egypt</p></div>-->
<!--                </div>-->
<!--                <div-->
<!--                    class="vamtam-has-theme-widget-styles elementor-element elementor-element-ed6f9b7 elementor-widget__width-inherit elementor-widget elementor-widget-button"-->
<!--                    data-element_type="widget" data-id="ed6f9b7" data-widget_type="button.default">-->
<!--                  <div class="elementor-widget-container">-->
<!--                    <div class="elementor-button-wrapper">-->
<!--                      <a class="elementor-button elementor-button-link elementor-size-sm"-->
<!--                         href="mailto:office@tecnologia.com">-->
<!--						<span class="elementor-button-content-wrapper">-->
<!--						<span class="elementor-button-text text-[#F5F5F1B3]">Email: info@eds-egypt.com</span>-->
<!--		</span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--                class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a88df03"-->
<!--                data-element_type="column" data-id="a88df03">-->
<!--              <div class="elementor-widget-wrap elementor-element-populated">-->

<!--                <div-->
<!--                    class="vamtam-has-theme-widget-styles elementor-element elementor-element-c84285d elementor-widget__width-inherit elementor-widget elementor-widget-button"-->
<!--                    data-element_type="widget" data-id="c84285d" data-widget_type="button.default">-->
<!--                  <div class="elementor-widget-container">-->
<!--                    <div class="elementor-button-wrapper">-->
<!--                      <a class="elementor-button elementor-button-link elementor-size-sm"-->
<!--                         href="tel:18003568933">-->
<!--						<span class="elementor-button-content-wrapper">-->
<!--						<span class="elementor-button-text text-[#F5F5F1B3]">+20 2 2521 4305/7</span>-->


<!--		</span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                    <div class="elementor-button-wrapper">-->
<!--                      <a class="elementor-button elementor-button-link elementor-size-sm"-->
<!--                         href="tel:18003568933">-->
<!--						<span class="elementor-button-content-wrapper">-->
<!--						<span class="elementor-button-text text-[#F5F5F1B3]">+20 2 2517 5977</span>-->


<!--		</span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                    <div class="elementor-button-wrapper">-->
<!--                      <a class="elementor-button elementor-button-link elementor-size-sm"-->
<!--                         href="tel:18003568933">-->
<!--						<span class="elementor-button-content-wrapper">-->
<!--						<span class="elementor-button-text text-[#F5F5F1B3]">+20 109 785 8008</span>-->


<!--		</span>-->
<!--                      </a>-->
<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->
        <div style="background-color: #242627 !important;" class="elementor-element elementor-element-67e90b7 e-flex e-con-boxed e-con e-parent"
             data-core-v316-plus="true" data-element_type="container" data-id="67e90b7"
             data-settings="{&quot;container_type&quot;:&quot;flex&quot;,&quot;content_width&quot;:&quot;boxed&quot;}">
          <div class="e-con-inner">
            <div class="elementor-element elementor-element-521d988 elementor-widget elementor-widget-heading"
                 data-element_type="widget" data-id="521d988" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default"> <aspan
                    style="color: rgb(115, 119, 123); font-family: Poppins, sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; text-align: center; white-space-collapse: collapse; ">Copyright © 2024 EDS. All rights reserved.</aspan>
                </h2></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
</script>

<style scoped >
p{
  padding:0 !important;
}
a{
  padding:4px !important;
}
</style>