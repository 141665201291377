<template>
  <div class="w-full h-[400px] bg-[#ac0000] text-white grid grid-cols-12 items-center overflow-hidden">
    <div style="color: white ; font-size: 72px" class="md:col-span-8  col-span-12 md:pl-[100px] pl-10 pb-10 md:pb-0">
      <p class="text-white font-thin text-2xl">// Our Solutions</p>
      Secure Box
    </div>
    <div class="md:col-span-4 col-span-12 h-full flex items-center overflow-hidden justify-center">
      <img src="../../assets/new_assets/EDS_Secure_Box.png" class="  " alt="SolutionHeader" />
    </div>
  </div>
</template>
