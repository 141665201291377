<template>
  <div class="bg-gray-100 min-h-screen flex items-center justify-center px-4">
    <div class="text-center">
      <!-- Large 404 Heading -->
      <h1 class="text-8xl font-bold text-gray-800">404</h1>
      <!-- Message -->
      <p class="mt-4 text-2xl text-gray-700">Page Not Found</p>
      <p class="mt-2 text-gray-500">
        The page you are looking for does not exist or has been moved.
      </p>
      <!-- Back to Home Button -->
      <div class="mt-6">
        <a
            href="/"
            class="px-6 py-3 bg-red-300 text-white text-lg font-medium rounded-lg shadow hover:bg-red-400 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Return to Homepage
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>
