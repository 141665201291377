<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// WE ARE IN THE ERA OF" page-name="Digital Archiving"/>
        <p class="my-10">
          Ever since the beginning of time, data preservation and management have been a constant process in one way or another. Now, with the
          digital era, this task has become more accessible, simpler, and at the tip of fingertips. These advancements are revolutionizing the way we
          handle and protect sensitive information. Enhanced security measures, including advanced encryption and multi-factor authentication,
          ensure your data remains protected from unauthorized access.
        </p>
        <p class="my-10">
          Furthermore, integrating cloud-based solutions allows for seamless collaboration and access to documents from anywhere worldwide,
          breaking down geographical barriers and fostering a more connected and efficient work environment.
        </p>
      </div>
    </div>

    <div class="md:col-span-5 col-span-12 md:col-start-9 rounded-[40px] overflow-hidden flex items-center justify-center">
      <img src="../../assets/new_assets/virtual_storage-min.png" class="w-full object-contain  shadow-2xl rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>