<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-d0d5a27 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="d0d5a27"
      data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}"
      style=" background: linear-gradient(to top, #FFFFFF ,rgba(255,174,174,0.18), #FFFFFF);"
  >
    <div class="elementor-background-overlay"></div>
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a83baf2"
          data-element_type="column" data-id="a83baf2">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-cae3941 elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="cae3941"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h2 class="elementor-heading-title elementor-size-default">
                Solutions</h2></div>
          </div>
          <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-c3ec24c elementor-section-height-min-height elementor-section-boxed elementor-section-height-default"
              data-element_type="section" data-id="c3ec24c">
            <div class="elementor-container elementor-column-gap-no">
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/document-and-media-storage">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-folder"
                                 style="color: #BE0000 ; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Document & Media Storage
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Secure and cost-effective solutions for storing business records and media assets, helping you
                            focus on business growth.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/document-and-media-storage">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/vault-storage">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-life-ring"
                                 style="color: #BE0000; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Vault Storage
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Provide a safe, secure, reliable, and compliant solution for all your document storage needs.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/vault-storage">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/digital-archiving">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-rectangle-list"
                                 style="color: #BE0000 ; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Digital Archiving
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Transform paper records into electronic copies for easy access with our digital scanning services.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/digital-archiving">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-8e19a7c elementor-section-height-min-height elementor-section-boxed elementor-section-height-default"
              data-element_type="section" data-id="8e19a7c">
            <div class="elementor-container elementor-column-gap-no">
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/asset-management-services">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-pen-to-square"
                                 style="color: #BE0000 ; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Asset Management
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Optimize asset inventory for efficiency and cost-effectiveness by refreshing, returning, or
                            removing assets as needed.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/asset-management-services">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/secure-destruction">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-trash-can"
                                 style="color: #BE0000 ;font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Secure Destruction
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Secure and eco-friendly destruction of various records on-site and off-site to maintain data
                            confidentiality.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/secure-destruction">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/data-entry">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-regular fa-keyboard"
                                 style="color: #BE0000; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Data Entry
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Accurate, efficient, and scalable data capture, entry, and processing for projects of all sizes.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/data-entry">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-dc5fcff elementor-section-height-min-height elementor-section-boxed elementor-section-height-default"
              data-element_type="section" data-id="dc5fcff">
            <div class="elementor-container elementor-column-gap-no">
              <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3196127"
                  data-element_type="column" data-id="3196127"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                      class="elementor-element elementor-element-fe24e76 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                      data-element_type="widget" data-id="fe24e76"
                      data-widget_type="call-to-action.default">
                    <div class="elementor-widget-container">
                      <link
                          href="../../wp-content/plugins/pro-elements/assets/css/widget-call-to-action.min.css"
                          rel="stylesheet">
                      <a class="elementor-cta" href="/customized-software-solutions">
                        <div class="elementor-cta__content">
                          <div
                              class="elementor-content-item elementor-cta__content-item elementor-icon-wrapper elementor-cta__icon elementor-view-default">
                            <div class="elementor-icon">
                              <i aria-hidden="true"
                                 class="fa-solid fa-sliders"
                                 style="color: #BE0000 ; font-size: 50px"></i></div>
                          </div>

                          <h3 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
                            Customized Solutions
                          </h3>

                          <div
                              class="elementor-cta__description elementor-cta__content-item elementor-content-item">
                            Implement custom innovative solutions that align with your organizational needs and business
                            objectives.
                          </div>

                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf6b635 elementor-widget elementor-widget-button"
                      data-element_type="widget" data-id="bf6b635"
                      data-widget_type="button.default">
                    <div class="elementor-widget-container">
                      <div class="elementor-button-wrapper">
                        <a class="elementor-button elementor-button-link elementor-size-sm"
                           href="/customized-software-solutions">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Learn more</span>
		</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </section>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-319fee7 elementor-align-center elementor-widget elementor-widget-button"
              data-element_type="widget" data-id="319fee7"
              data-widget_type="button.default">
            <div class="elementor-widget-container">
              <div class="elementor-button-wrapper">

						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text"></span>
		</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script setup>
</script>