<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-34b17d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="34b17d4">
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b0614f"
          data-element_type="column" data-id="4b0614f">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-bfc8ba4 elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="bfc8ba4"
              data-widget_type="heading.default">

          </div>
          <div
              class="elementor-element elementor-element-1de06c5 elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="1de06c5"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h2 class="elementor-heading-title elementor-size-default">Our
                Commitment: Delivering Excellence in Data Management</h2></div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles  elementor-element elementor-element-4b4fc5e elementor-position-left elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-vertical-align-middle elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="8492b4a"
              data-widget_type="image-box.default">
            <div class="elementor-widget-container ">
              <div class="elementor-image-box-wrapper rounded-3xl bg-amber-400">
                <figure class="elementor-image-box-img">
                  <div class="p-1"
                       tabindex="-1"><img alt=""
                                          class="attachment-medium size-medium wp-image-562"
                                          decoding="async"
                                          loading="lazy"
                                          src="../../assets/target.png"
                                          style="background-color: white ; padding: 5px ;"
                                          />
                  </div>
                </figure>
                <div class="elementor-image-box-content justify-center "><h6 style="margin: 0 !important;"
                    class="elementor-image-box-title items-center">Quality</h6></div>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="78565e8"
              data-widget_type="image-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-image-box-wrapper">
                <figure class="elementor-image-box-img">
                  <div class="p-1"
                       tabindex="-1"><img alt=""
                                          class="attachment-medium size-medium wp-image-563"
                                          decoding="async" height="300"
                                          loading="lazy"
                                          sizes="(max-width: 300px) 100vw, 300px"
                                          src="../../assets/cyber-security.png"
                                          style="width: 100% ; background-color: white ; padding: 5px"
                                          width="250"/></div>
                </figure>
                <div class="elementor-image-box-content">
                  <h6 class="elementor-image-box-title" style="margin: 0 !important;">
                    <div>Security</div>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="9c018eb"
              data-widget_type="image-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-image-box-wrapper">
                <figure class="elementor-image-box-img">
                  <div class="p-1"
                       tabindex="-1"><img alt=""
                                          class="attachment-medium size-medium wp-image-564"
                                          decoding="async" loading="lazy"
                                          sizes="(max-width: 300px) 100vw, 300px"
                                          src="../../assets/efficiency.png"
                                          style="width: 90%; background-color: white; padding: 5px"/>
                  </div>
                </figure>
                <div class="elementor-image-box-content">
                  <h6 class="elementor-image-box-title" style="margin: 0 !important;">
                    <div>Efficiency</div>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="9c018eb"
              data-widget_type="image-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-image-box-wrapper">
                <figure class="elementor-image-box-img">
                  <div class="p-1"
                       tabindex="-1"><img alt=""
                                          class="attachment-medium size-medium wp-image-564"
                                          decoding="async" loading="lazy"
                                          sizes="(max-width: 300px) 100vw, 300px"
                                          src="../../assets/recycle.png"
                                          style=" background-color: white ; padding :1px;width: 90%;"/>
                  </div>
                </figure>
                <div class="elementor-image-box-content">
                  <h6 class="elementor-image-box-title" style="margin: 0 !important;">
                    <div>Sustainability</div>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="b1e7994"
              data-widget_type="image-box.default">
            <div class="elementor-widget-container">
              <div class="elementor-image-box-wrapper">
                <figure class="elementor-image-box-img">
                  <div class="p-1"
                       tabindex="-1"><img alt=""
                                          class="attachment-medium size-medium wp-image-565"
                                          decoding="async" height="300"
                                          loading="lazy"
                                          sizes="(max-width: 300px) 100vw, 300px"
                                          src="../../assets/dependable.png"
                                          style="padding: 5px; background-color: white"
                                          width="300"/></div>
                </figure>
                <div class="elementor-image-box-content">
                  <h6 class="elementor-image-box-title" style="margin: 0 !important;">
                    <div>Reliability</div>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-vertical-align-middle elementor-widget elementor-widget-image-box"
              data-element_type="widget" data-id="4b4fc5e"
              data-widget_type="image-box.default">
            <div
                class="vamtam-has-theme-widget-styles elementor-element elementor-element-4b4fc5e elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image-box"
                data-element_type="widget" data-id="2d15b3b"
                data-widget_type="image-box.default">
              <div class="elementor-widget-container">
                <div class="elementor-image-box-wrapper">
                  <figure class="elementor-image-box-img">
                    <div class="p-1"
                         tabindex="-1"><img alt=""

                                            class="attachment-medium size-medium  wp-image-567"
                                            decoding="async" height="300"
                                            loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px"
                                            src="../../assets/service.png"
                                            style=" background-color: white; padding: 5px"
                                            width="300"/></div>
                  </figure>
                  <div class="elementor-image-box-content flex items-center">
                    <h6 class="elementor-image-box-title p-0 m-0" style="margin: 0 !important;">
                      Customization
                    </h6>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div></div>
  </section>

</template>
<script setup>
</script>