
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
const head = createHead();
import './main.css'
import './output.css'
import gtag from 'vue-gtag';

createApp(App).use(gtag, {
    config: { id: 'G-DLYYL3RLGR' }, // Use your actual GA4 or Universal Analytics ID here
}).use(head).use(router).mount('#app');
