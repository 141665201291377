<template>
  <div>
    <app-header/>
    <content-page />
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import ContentPage from "@/components/Facility/ContentPage.vue";
export default {
  components:{ContentPage, AppHeader,AppFooter},

}
</script>