<template>
  <div>
    <app-header/>
    <page-content />
    <form-component class="mb-20"/>
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import FormComponent from "@/components/SharedComponents/FormComponent.vue";
import PageContent from "@/components/AboutUs/PageContent.vue";
export default {
  components: {AppHeader, AppFooter, FormComponent ,PageContent},
}
</script>