<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name page-name="Commitment to Sustainability"/>
        <p class="my-10">
          At EDS, we believe in the possibility of environmentally-conscious information management and
          its imperative in today's rapidly evolving business landscape. We are committed to being our customers'
          most trusted partner for protecting and unlocking the value of their most critical information in innovative
          and socially responsible ways. Embracing Sustainability principles, we strive to embed them into every aspect of our operations,
          from energy-efficient facilities to responsible waste management practices, dedicated to minimizing our environmental footprint
          and preserving natural resources for future generations.

        </p>
        <p class="my-10">
          Together with our customers, we challenge ourselves to create innovative business
          solutions that drive positive environmental and social impact. Fostering collaboration and leveraging
          cutting-edge technologies, we empower our customers to make informed
          decisions that align with Sustainability goals, supporting them in managing their valuable information and assets in a more sustainable manner.
        </p>
        <p class="my-10">
          Beyond environmental initiatives, our commitment to Sustainability extends to cultivating a culture of diversity and inclusion within our organization. Recognizing that diverse perspectives drive innovation and foster positive social change; we support and empower our employees to deliver on our promises to customers and communities inclusively and impactfully.
        </p> <p class="my-10">
        At EDS, we leverage our influence and expertise to drive innovations that not only protect and elevate the power of our customers' work but also make a lasting, positive impact on people, the planet, and performance. Sustainability isn't just a goal for us—it's a promise we uphold in everything we do, working towards a more resilient and equitable future for all        </p>
      </div>
    </div>
    <div class="md:col-span-5 md:col-start-9 col-span-12">
      <img src="../../assets/new_assets/Commitment_to_Sustainability.jpeg" class="w-full shadow-2xl rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>