<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// OUR SOLUTIONS" page-name="Data Entry"/>
        <p class="my-10">
          Improve your data management processes to ensure
          accuracy, efficiency, and scalability. Whether you require
          quick resource support for larger projects or seek a reliable
          partner for ongoing data operations, EDS possesses the
          expertise and capacity to support you in handling projects
          of various scales and complexities.
        </p>
        <p class="my-10">
          Our commitment to precision and quality ensures that your
          data is captured, processed, and organized accurately. Our
          comprehensive Data Entry services encompass a wide
          array of data capture activities.
        </p>
      </div>
    </div>
    <div class="md:col-span-5 md:col-start-9 col-span-12">
      <img src="../../assets/new_assets/data_entry-min.png" class="w-full shadow-2xl h-full rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>