<template>
  <div>
    <app-header/>
    <div class="w-full mt-10 text-center">
      <h1>Contact Us</h1>
    </div>
    <form-component style="margin: 50px 0"/>
    <app-footer/>
  </div>
</template>
<script>
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import FormComponent from "@/components/SharedComponents/FormComponent.vue";
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
export default {
  components:{AppHeader,FormComponent,AppFooter},
}
</script>