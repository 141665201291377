<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// OUR SOLUTIONS" page-name="Vault Storage"/>
        <p class="my-10 text-lg">
          <span class="font-bold text-[#BC0000]">Dedicated Space:</span> A fully enclosed, private vault ensures your
          documents are stored securely and out of sight.
        </p>
        <p class="my-10 text-lg">
          <span class="font-bold text-[#BC0000]">Maximum Security:</span> 24/7 surveillance, biometric access, and strict entry
          controls to safeguard your documents.
        </p>
        <p class="my-10 text-lg">
          <span class="font-bold text-[#BC0000]">Climate Control:</span> Vaults are kept at ideal temperature and humidity for
          long-term document preservation.
        </p>
        <p class="my-10 text-lg">
          <span class="font-bold text-[#BC0000]">Fire Safety:</span> Equipped with automatic gas fire suppression systems for
          swift response.
        </p>
        <p class="my-10 text-lg">
          <span class="font-bold text-[#BC0000]">Fireproof Doors:</span> Reinforced fireproof doors for extra protection against
          hazards.
        </p>
      </div>
    </div>

    <div class="md:col-span-5 col-span-12 md:col-start-9 rounded-[40px] overflow-hidden flex items-center justify-center">
      <img src="../../assets/new_assets/Vault_storage.png" width="100" class="w-full shadow-2xl object-contain rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>