<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// OUR SOLUTIONS" page-name="Asset Management"/>
        <p class="my-10">
          EDS Asset Management Services offer end-to-end asset management solutions, including
          planning, acquisition, tracking, and disposal, tailored to meet organizational needs for optimal
          asset performance.

        </p>
        <p class="my-10">
          We proactively optimize asset inventory for efficiency and cost-effectiveness by refreshing,
          returning, or removing assets as needed.
        </p>
        <div class="w-full grid grid-cols-12">
          <div class="col-span-12 rounded-xl p-4 grid md:h-40 h-auto grid-cols-12" style="border: 1px solid lightgray">
            <div class="md:col-span-3 col-span-12 flex justify-center h-full items-center rounded-lg overflow-hidden" style="border: 2px solid #dfa7a7 ; outline: 2px solid #e49c9c">
              <img src="../../assets/new_assets/assetIconOne.png" class="w-full object-cover h-full" alt="asset-icon" />
            </div>
            <div class="md:col-span-9 col-span-12 grid items-center justify-center">
              <p class="m-3 text-gray-500">
                Tagging assets ensures easy traceability, allowing
                quick identification, tracking of history, and
                streamlined asset audits or future reference.
              </p>
            </div>
          </div>
          <div class="col-span-12 my-5 rounded-xl  md:h-40 h-auto p-4 grid grid-cols-12" style="border: 1px solid lightgray">
            <div class="md:col-span-3  col-span-12 flex justify-center h-full items-center rounded-lg overflow-hidden" style="border: 2px solid #dfa7a7 ; outline: 2px solid #e49c9c">
              <img src="../../assets/new_assets/assetIconTwo.png" class="w-full object-cover h-full" alt="asset-icon" />
            </div>
            <div class="md:col-span-9 col-span-12 grid items-center justify-center">
              <p class="m-3  text-gray-500">
                Centralized platform for managing assets, offering a consolidated inventory view for
                informed decisions and enhanced asset utilization.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:col-span-5 md:col-start-8 col-span-12">
      <img src="../../assets/new_assets/Asset_Management%20(1).png" class="shadow-2xl rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>