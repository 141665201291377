<template>
  <div class="vamtam-main layout-full" id="main" role="main">
    <article class="full post-7175 page type-page status-publish has-post-thumbnail hentry" id="post-7175">
      <div class="elementor elementor-7271 elementor-location-single post-7175 page type-page status-publish has-post-thumbnail hentry"
           data-elementor-id="7271" data-elementor-post-type="elementor_library"
           data-elementor-type="single-page">
        <section
            class="elementor-section elementor-top-section elementor-element elementor-element-6a833d05 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-element_type="section" data-id="6a833d05">
          <div class="elementor-container elementor-column-gap-default">
            <div  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2712c116"
                 data-element_type="column" data-id="2712c116">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-bae942c elementor-widget elementor-widget-spacer"
                     data-element_type="widget" data-id="bae942c" data-widget_type="spacer.default">
                  <div class="elementor-widget-container">
                    <div class="elementor-spacer">
                      <div class="elementor-spacer-inner"></div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="elementor-element elementor-element-25d466f animated-slow elementor-invisible elementor-widget elementor-widget-heading"
                       data-element_type="widget" data-id="25d466f"
                       data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;}"
                       data-widget_type="heading.default">
                    <div class="elementor-widget-container" style="text-align: center">
                      <h1 class=" elementor-size-default " style="font-size: 40px">About Us</h1>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-879288b animated-slow elementor-invisible elementor-widget elementor-widget-theme-post-excerpt"
                       data-element_type="widget" data-id="879288b"
                       data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;,&quot;_animation_delay&quot;:300}"
                       data-widget_type="theme-post-excerpt.default">
                    <div class="elementor-widget-container mx-20" style="text-align: start"> EDS is a leading document management company in Egypt, catering to businesses of various
                      sizes and industries. With a commitment to excellence and innovation, we provide
                      comprehensive solutions that streamline workflow, enhance productivity, and ensure the security
                      15
                      of sensitive information. From advanced digital archiving to efficient document retrieval systems,
                      we offer a range of services tailored to meet the unique needs of your business.

                    </div>
                  </div>

                </div>
               <div class="vamtam-has-theme-widget-styles elementor-element elementor-element-bf883f1 elementor-align-center elementor-widget elementor-widget-button"
                     data-element_type="widget" data-id="bf883f1" data-widget_type="button.default">
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">

                    </div>
                  </div>
                </div>
                <div class="elementor-element elementor-element-badea6b elementor-widget elementor-widget-spacer"
                     data-element_type="widget" data-id="badea6b" data-widget_type="spacer.default">
                  <div class="elementor-widget-container">
                    <div class="elementor-spacer">
                      <div class="elementor-spacer-inner"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
            class=" elementor-section elementor-top-section elementor-element elementor-element-e6b8707 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-element_type="section" data-id="e6b8707">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5511272"
                 data-e-bg-lazyload=".elementor-element-populated" data-element_type="column"
                 data-id="5511272"
                 data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
              <div class="elementor-widget-wrap ">
                <img src="../../wp-content/uploads/2023/03/about-us.jpeg" alt=""/>
              </div>
            </div>
          </div>
        </section>
        <section
            class=" elementor-section elementor-top-section elementor-element elementor-element-19b238b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-element_type="section" data-id="19b238b">
          <div class="elementor-container elementor-column-gap-no">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4183bc03"
                 data-element_type="column" data-id="4183bc03">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-7892e2e9 elementor-widget elementor-widget-theme-post-content"
                     data-element_type="widget" data-id="7892e2e9"
                     data-widget_type="theme-post-content.default">
                  <div class="elementor-widget-container">
                    <div class="elementor elementor-7175" data-elementor-id="7175"
                         data-elementor-post-type="page" data-elementor-type="wp-page">
                      <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-2184cfd2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-element_type="section" data-id="2184cfd2">
                        <div class="elementor-container elementor-column-gap-no">
                          <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63c01579"
                               data-element_type="column" data-id="63c01579">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-3348eb71 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                                   data-element_type="widget" data-id="3348eb71"
                                   data-widget_type="heading.default">
                              </div>
                              <div class="elementor-element elementor-element-189105df elementor-widget elementor-widget-text-editor"
                                   data-element_type="widget" data-id="189105df"
                                   data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                          class="mt-10 elementor-section elementor-top-section elementor-element elementor-element-a4062ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-element_type="section" data-id="a4062ae">
                        <div class="elementor-container elementor-column-gap-no">
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2540f6a1"
                               data-element_type="column" data-id="2540f6a1">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-4f718d9b elementor-widget elementor-widget-heading"
                                   data-element_type="widget" data-id="4f718d9b"
                                   data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                  <h2 class="elementor-heading-title elementor-size-default">
                                    Why EDS?</h2></div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-14e94a09 elementor-hidden-tablet elementor-hidden-mobile"
                               data-element_type="column" data-id="14e94a09">
                            <div class="elementor-widget-wrap">
                              <div class="elementor-widget-container">
                                <p>
                                  The most mature and equipped document and data management brand in Egypt :
                                </p>
                                <p class="mx-3"> • Protecting your most valuable assets data & documents.</p>
                                <p class="mx-3"> • Secure data, document, and record management.</p>
                                <p class="mx-3"> • On-call dedicated support and resources.</p>
                                <p class="mx-3"> • Robust digital solutions for scattered data and documents.</p>
                                <p class="mx-3"> • World-class physical archiving facilities.</p></div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                          class="mt-10 elementor-section elementor-top-section elementor-element elementor-element-a4062ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-element_type="section" data-id="a4062ae">
                        <div class="elementor-container elementor-column-gap-no">
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2540f6a1"
                               data-element_type="column" data-id="2540f6a1">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-4f718d9b elementor-widget elementor-widget-heading"
                                   data-element_type="widget" data-id="4f718d9b"
                                   data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                  <h2 class="elementor-heading-title elementor-size-default">
                                    Our Values</h2></div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-14e94a09 elementor-hidden-tablet elementor-hidden-mobile"
                               data-element_type="column" data-id="14e94a09">
                            <div class="elementor-widget-wrap">
                              <div class="elementor-widget-container relative right-3">
                                <ul class="elementor-icon-list-items">
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>
                                    <span class="elementor-icon-list-text">• Our Passion for Data
										</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>
                                    <span class="elementor-icon-list-text" style="margin-top: 5px">• People-First</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>
                                    <span class="elementor-icon-list-text" style="margin-top: 5px">• Sustainable Operations</span>
                                  </li> <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>
                                  <span class="elementor-icon-list-text" style="margin-top: 5px">• Continuous Improvement</span>
                                </li> <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>
                                  <span class="elementor-icon-list-text" style="margin-top: 5px">• Ethical Business Practices</span>
                                </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                          class="mt-10 elementor-section elementor-top-section elementor-element elementor-element-a4062ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-element_type="section" data-id="a4062ae">
                        <div class="elementor-container elementor-column-gap-no">
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2540f6a1"
                               data-element_type="column" data-id="2540f6a1">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-4f718d9b elementor-widget elementor-widget-heading"
                                   data-element_type="widget" data-id="4f718d9b"
                                   data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                  <h2 class="elementor-heading-title elementor-size-default">
                                    Our Mission</h2></div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-14e94a09 elementor-hidden-tablet elementor-hidden-mobile"
                               data-element_type="column" data-id="14e94a09">
                            <div class="elementor-widget-wrap">
                              <div class="elementor-widget-container">
                                <div>
                                  Our mission is to drive the evolution of document management, promising unmatched
                                  convenience, security, and intelligence.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
            class=" mt-20 elementor-section bg-lanier elementor-top-section elementor-element elementor-element-19b238b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-element_type="section" data-id="19b238b">
          <div class="elementor-container  elementor-column-gap-no">
            <div class="elementor-column  elementor-col-100 elementor-top-column elementor-element elementor-element-4183bc03"
                 data-element_type="column" data-id="4183bc03">
              <div class="elementor-widget-wrap  elementor-element-populated">
                <div class="elementor-element  elementor-element-7892e2e9 elementor-widget elementor-widget-theme-post-content"
                     data-element_type="widget" data-id="7892e2e9"
                     data-widget_type="theme-post-content.default">
                  <div class="elementor-widget-container ">
                    <div class="elementor elementor-7175" data-elementor-id="7175"
                         data-elementor-post-type="page" data-elementor-type="wp-page">
                      <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-2184cfd2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-element_type="section" data-id="2184cfd2">
                        <div class="elementor-container elementor-column-gap-no">
                          <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63c01579"
                               data-element_type="column" data-id="63c01579">
                            <div class="elementor-widget-wrap elementor-element-populated ">
                              <div class="elementor-element  elementor-element-3348eb71 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                                   data-element_type="widget" data-id="3348eb71"
                                   data-widget_type="heading.default">
                              </div>
                              <div class="elementor-element  elementor-element-189105df elementor-widget elementor-widget-text-editor"
                                   data-element_type="widget" data-id="189105df"
                                   data-widget_type="text-editor.default">
                                <div class="elementor-widget-container ">
                                  <div class="grid-cols-12 grid ">
                                    <div class="col-span-12 my-3">
                                      // Our Track Record
                                    </div>
                                    <div class="col-span-12 my-3">
                                      <h2>
                                        Evolving global businesses across multiple industries
                                      </h2>
                                    </div>
                                    <div class="grid grid-cols-12 col-span-12 mt-10" style="border-bottom: 1px solid #e3e3e3">
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/HC.png" class="w-1/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Pfizer.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Novo%20Nordisk.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Schlumberger.png" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Fairmont.png" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 flex items-center justify-center ">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Four%20Seasons.png" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                    </div>
                                    <div class="grid grid-cols-12 col-span-12 " style="border-bottom: 1px solid #e3e3e3">
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Unilever%20Transparent.png" class="w-1/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Hilton.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Marriott.png" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Jotun.png" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/KPMG.svg" class="w-2/3 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3  flex items-center justify-center">
                                        <img src="../../assets/new_assets/Customers_%20Logos/HSBC.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                    </div>
                                    <div class="grid grid-cols-12 col-span-12 " style="border-bottom: 1px solid #e3e3e3">
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Fulbright.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Shell.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Mcdonald_s.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Henkel.svg" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Etisalat%20(new%20Logo).png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 ">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Dar%20Al%20Foad.png" class="w-3/4 ml-4 object-contain" alt="logo" />
                                      </div>
                                    </div>
                                    <div class="grid grid-cols-12 col-span-12 " style="border-bottom: 1px solid #e3e3e3">
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Magrabi%20Agriculture.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Western%20Union%20Black.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Astrazeneca.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Total.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Egypt%20Factors.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 flex items-center justify-center">
                                        <img src="../../assets/new_assets/Customers_%20Logos/APL%20(Black%20Logo)%20-%20Transparent%20background.png" class="w-2/4 object-contain" alt="logo" />
                                      </div>
                                    </div>
                                    <div class="grid grid-cols-12 col-span-12 mb-10 ">
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/AUC.png" class="w-full object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Chevron.png" class="w-1/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Philip%20Morris%20international.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Kempinski%20Hoteliers%20(transparent).png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 border-r">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Petromin.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                      <div class="md:col-span-2 col-span-4 p-3 flex items-center justify-center ">
                                        <img src="../../assets/new_assets/Customers_%20Logos/Lilly.png" class="w-3/4 object-contain" alt="logo" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </article>
  </div>

</template>
<style scoped>/*! elementor - v3.17.0 - 01-10-2023 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-] > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
  font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
  font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
  font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
  font-size: 59px
}
.elementor-column .elementor-spacer-inner {
  height: var(--spacer-size)
}

.e-con {
  --container-widget-width: 100%
}

.e-con-inner > .elementor-widget-spacer, .e-con > .elementor-widget-spacer {
  width: var(--container-widget-width, var(--spacer-size));
  --align-self: var(--container-widget-align-self, initial);
  --flex-shrink: 0
}

.e-con-inner > .elementor-widget-spacer > .elementor-widget-container, .e-con > .elementor-widget-spacer > .elementor-widget-container {
  height: 100%;
  width: 100%
}

.e-con-inner > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer, .e-con > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer {
  height: 100%
}

.e-con-inner > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer > .elementor-spacer-inner, .e-con > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer > .elementor-spacer-inner {
  height: var(--container-widget-height, var(--spacer-size))
}

.e-con-inner > .elementor-widget-spacer.elementor-widget-empty, .e-con > .elementor-widget-spacer.elementor-widget-empty {
  position: relative;
  min-height: 22px;
  min-width: 22px
}

.e-con-inner > .elementor-widget-spacer.elementor-widget-empty .elementor-widget-empty-icon, .e-con > .elementor-widget-spacer.elementor-widget-empty .elementor-widget-empty-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0;
  width: 22px;
  height: 22px
}
.elementor-widget-video .elementor-widget-container {
  overflow: hidden;
  transform: translateZ(0)
}

.elementor-widget-video .elementor-wrapper {
  aspect-ratio: var(--video-aspect-ratio)
}

.elementor-widget-video .elementor-wrapper iframe, .elementor-widget-video .elementor-wrapper video {
  height: 100%;
  width: 100%;
  display: flex;
  border: none;
  background-color: #000
}

@supports not (aspect-ratio:1/1) {
  .elementor-widget-video .elementor-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(100% / var(--video-aspect-ratio))
  }

  .elementor-widget-video .elementor-wrapper iframe, .elementor-widget-video .elementor-wrapper video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }
}

.elementor-widget-video .elementor-open-inline .elementor-custom-embed-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50%
}

.elementor-widget-video .elementor-custom-embed-image-overlay {
  cursor: pointer;
  text-align: center
}

.elementor-widget-video .elementor-custom-embed-image-overlay:hover .elementor-custom-embed-play i {
  opacity: 1
}

.elementor-widget-video .elementor-custom-embed-image-overlay img {
  display: block;
  width: 100%;
  aspect-ratio: var(--video-aspect-ratio);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center
}

@supports not (aspect-ratio:1/1) {
  .elementor-widget-video .elementor-custom-embed-image-overlay {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(100% / var(--video-aspect-ratio))
  }

  .elementor-widget-video .elementor-custom-embed-image-overlay img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }
}

.elementor-widget-video .e-hosted-video .elementor-video {
  -o-object-fit: cover;
  object-fit: cover
}

.e-con-inner > .elementor-widget-video, .e-con > .elementor-widget-video {
  width: var(--container-widget-width);
  --flex-grow: var(--container-widget-flex-grow)
}
.elementor-button.elementor-hidden, .elementor-hidden {
  display: none
}

.e-form__step {
  width: 100%
}

.e-form__step:not(.elementor-hidden) {
  display: flex;
  flex-wrap: wrap
}

.e-form__buttons {
  flex-wrap: wrap
}

.e-form__buttons, .e-form__buttons__wrapper {
  display: flex
}

.e-form__indicators {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 13px;
  margin-bottom: var(--e-form-steps-indicators-spacing)
}

.e-form__indicators__indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
  padding: 0 var(--e-form-steps-divider-gap)
}

.e-form__indicators__indicator__progress {
  width: 100%;
  position: relative;
  background-color: var(--e-form-steps-indicator-progress-background-color);
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  overflow: hidden
}

.e-form__indicators__indicator__progress__meter {
  width: var(--e-form-steps-indicator-progress-meter-width, 0);
  height: var(--e-form-steps-indicator-progress-height);
  line-height: var(--e-form-steps-indicator-progress-height);
  padding-right: 15px;
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  background-color: var(--e-form-steps-indicator-progress-color);
  color: var(--e-form-steps-indicator-progress-meter-color);
  text-align: right;
  transition: width .1s linear
}

.e-form__indicators__indicator:first-child {
  padding-left: 0
}

.e-form__indicators__indicator:last-child {
  padding-right: 0
}

.e-form__indicators__indicator--state-inactive {
  color: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2)
}

.e-form__indicators__indicator--state-inactive [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-inactive-secondary-color, #fff)
}

.e-form__indicators__indicator--state-inactive object, .e-form__indicators__indicator--state-inactive svg {
  fill: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2)
}

.e-form__indicators__indicator--state-active {
  color: var(--e-form-steps-indicator-active-primary-color, #39b54a);
  border-color: var(--e-form-steps-indicator-active-secondary-color, #fff)
}

.e-form__indicators__indicator--state-active [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-active-secondary-color, #fff)
}

.e-form__indicators__indicator--state-active object, .e-form__indicators__indicator--state-active svg {
  fill: var(--e-form-steps-indicator-active-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed {
  color: var(--e-form-steps-indicator-completed-secondary-color, #fff)
}

.e-form__indicators__indicator--state-completed [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-completed-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed .e-form__indicators__indicator__label {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed .e-form__indicators__indicator--shape-none {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
  background-color: initial
}

.e-form__indicators__indicator--state-completed object, .e-form__indicators__indicator--state-completed svg {
  fill: var(--e-form-steps-indicator-completed-secondary-color, #fff)
}

.e-form__indicators__indicator__icon {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  font-size: var(--e-form-steps-indicator-icon-size);
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px
}

.e-form__indicators__indicator__icon img, .e-form__indicators__indicator__icon object, .e-form__indicators__indicator__icon svg {
  width: var(--e-form-steps-indicator-icon-size);
  height: auto
}

.e-form__indicators__indicator__icon .e-font-icon-svg {
  height: 1em
}

.e-form__indicators__indicator__number {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px
}

.e-form__indicators__indicator--shape-circle {
  border-radius: 50%
}

.e-form__indicators__indicator--shape-square {
  border-radius: 0
}

.e-form__indicators__indicator--shape-rounded {
  border-radius: 5px
}

.e-form__indicators__indicator--shape-none {
  border: 0
}

.e-form__indicators__indicator__label {
  text-align: center
}

.e-form__indicators__indicator__separator {
  width: 100%;
  height: var(--e-form-steps-divider-width);
  background-color: #babfc5
}

.e-form__indicators--type-icon, .e-form__indicators--type-icon_text, .e-form__indicators--type-number, .e-form__indicators--type-number_text {
  align-items: flex-start
}

.e-form__indicators--type-icon .e-form__indicators__indicator__separator, .e-form__indicators--type-icon_text .e-form__indicators__indicator__separator, .e-form__indicators--type-number .e-form__indicators__indicator__separator, .e-form__indicators--type-number_text .e-form__indicators__indicator__separator {
  margin-top: calc(var(--e-form-steps-indicator-padding, 30px) / 2 - var(--e-form-steps-divider-width, 1px) / 2)
}

.elementor-field-type-hidden {
  display: none
}

.elementor-field-type-html {
  display: inline-block
}

.elementor-login .elementor-lost-password, .elementor-login .elementor-remember-me {
  font-size: .85em
}

.elementor-field-type-recaptcha_v3 .elementor-field-label {
  display: none
}

.elementor-field-type-recaptcha_v3 .grecaptcha-badge {
  z-index: 1
}

.elementor-button .elementor-form-spinner {
  order: 3
}

.elementor-form .elementor-button > span {
  display: flex;
  justify-content: center;
  align-items: center
}

.elementor-form .elementor-button .elementor-button-text {
  white-space: normal;
  flex-grow: 0
}

.elementor-form .elementor-button svg {
  height: auto
}

.elementor-form .elementor-button .e-font-icon-svg {
  height: 1em
}

.elementor-select-wrapper .select-caret-down-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  inset-inline-end: 10px;
  pointer-events: none;
  font-size: 11px
}

.elementor-select-wrapper .select-caret-down-wrapper svg {
  display: unset;
  width: 1em;
  aspect-ratio: unset;
  fill: currentColor
}

.elementor-select-wrapper .select-caret-down-wrapper i {
  font-size: 19px;
  line-height: 2
}

.elementor-select-wrapper.remove-before:before {
  content: "" !important
}
.border-r{
  border-right: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  }
@media (max-width: 640px) { /* Adjust breakpoint as needed */
  .border-r{
    border-right: 0 solid #e3e3e3
  }
}
</style>
<script setup lang="ts">
</script>