<template>
  <section

      class="elementor-section elementor-top-section elementor-element elementor-element-4f198ee elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="4f198ee">
    <div style="display: flex ; justify-content: center ; align-items: center" class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-6eb14b6"
          data-element_type="column" data-id="6eb14b6">
        <div class="elementor-widget-wrap elementor-element-populated"
             style="display:flex ; justify-content: center ; text-align: center">
          <div
              class="elementor-element elementor-element-9865cd6 elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="9865cd6"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default">20+</span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-8446117 elementor-widget__width-initial elementor-absolute e-transform elementor-widget elementor-widget-spacer"
              data-element_type="widget" data-id="8446117"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:90,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="spacer.default">
          </div>
          <div
              class="elementor-element elementor-element-41c3fb6 elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="41c3fb6"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default">YEARS</span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-307a0bd elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="307a0bd"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p  style="text-align: center !important;"> of Experience</p>
            </div>
          </div>
        </div>
      </div>
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-71db7cb"
          data-element_type="column" data-id="71db7cb">
        <div class="elementor-widget-wrap elementor-element-populated"
             style="display:flex ; justify-content: center ; text-align: center">
          <div
              class="elementor-element elementor-element-2542b3d elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="2542b3d"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default">200+</span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-132f0a8 elementor-widget__width-initial elementor-absolute e-transform elementor-widget elementor-widget-spacer"
              data-element_type="widget" data-id="132f0a8"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:90,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="spacer.default">
            <div class="elementor-widget-container">
              <div class="elementor-spacer">
                <div class="elementor-spacer-inner"></div>
              </div>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-77816e8 elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="77816e8"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default"></span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-c29c494 elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="c29c494"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p  style="text-align: center !important;">Customers Served</p></div>
          </div>
        </div>
      </div>
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4db22da"
          data-element_type="column" data-id="4db22da">
        <div class="elementor-widget-wrap elementor-element-populated"
             style="display:flex ; justify-content: center ; text-align: center">
          <div
              class="elementor-element elementor-element-813ef52 elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="813ef52"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default">100+ Tons</span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-3b2be3e elementor-widget__width-initial elementor-absolute e-transform elementor-widget elementor-widget-spacer"
              data-element_type="widget" data-id="3b2be3e"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:90,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="spacer.default">
            <div class="elementor-widget-container">
              <div class="elementor-spacer">
                <div class="elementor-spacer-inner"></div>
              </div>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-6e7033a elementor-widget__width-auto elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="6e7033a"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <span class="elementor-heading-title elementor-size-default"></span>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-9d4de59 elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="9d4de59"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p  style="text-align: center !important;"> of Co2 Saved/Year</p></div>
          </div>
        </div>
      </div>
<!--      <div-->
<!--          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-a7e89df"-->
<!--          data-element_type="column" data-id="a7e89df">-->
<!--        <div class="elementor-widget-wrap elementor-element-populated"-->
<!--             style="display:flex ; justify-content: center ; text-align: center">-->
<!--          <div-->
<!--              class="elementor-element elementor-element-94637fe elementor-widget__width-auto elementor-widget elementor-widget-heading"-->
<!--              data-element_type="widget" data-id="94637fe"-->
<!--              data-widget_type="heading.default">-->
<!--            <div class="elementor-widget-container">-->
<!--              <span class="elementor-heading-title elementor-size-default">+1700</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--              class="elementor-element elementor-element-fa7c2f9 elementor-widget__width-initial elementor-absolute e-transform elementor-widget elementor-widget-spacer"-->
<!--              data-element_type="widget" data-id="fa7c2f9"-->
<!--              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:90,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"-->
<!--              data-widget_type="spacer.default">-->
<!--            <div class="elementor-widget-container">-->
<!--              <div class="elementor-spacer">-->
<!--                <div class="elementor-spacer-inner"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--              class="elementor-element elementor-element-6b31220 elementor-widget__width-auto elementor-widget elementor-widget-heading"-->
<!--              data-element_type="widget" data-id="6b31220"-->
<!--              data-widget_type="heading.default">-->
<!--            <div class="elementor-widget-container">-->
<!--              <span class="elementor-heading-title elementor-size-default">Over</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--              class="elementor-element elementor-element-0830b93 elementor-widget elementor-widget-text-editor"-->
<!--              data-element_type="widget" data-id="0830b93"-->
<!--              data-widget_type="text-editor.default">-->
<!--            <div class="elementor-widget-container">-->
<!--              <p  style="text-align: center !important;">Trees Saved Annually</p></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </section>
</template>

<script setup>

</script>