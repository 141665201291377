<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// OUR SOLUTIONS" page-name="Secure destruction"/>
        <p class="my-10 ">
          Disposing of outdated or confidential records is a legal and cost-
          effective necessity. Mishandling sensitive information can pose
          significant risks to organizations, customers, and employees.
        </p>
        <p class="mt-10 ">
          We provide on-site and off-site destruction services, recycling all
          waste in an eco-friendly manner to support our sustainability goals.
          Our services include the secure destruction of various records to
          maintain data confidentiality.
        </p>
      </div>
      <div class="col-span-12 grid grid-cols-12 p-4 md:p-20   ">
        <div class="col-span-12 md:col-span-3 flex flex-col items-center justify-center">
          <i class="fa-regular fa-file color-main text-4xl"></i>
          <p class="text-lg my-4 color-main">Paper Documents</p>
        </div>
        <div class="col-span-12 md:col-span-3 flex flex-col items-center justify-center">
          <i class="fa-solid fa-compact-disc color-main text-4xl"></i>
          <p class="text-lg my-4 color-main">Taps & CDs</p>
        </div>
        <div class="col-span-12 md:col-span-3 flex flex-col items-center justify-center">
          <i class="fa-regular fa-hard-drive color-main text-4xl"></i>
          <p class="text-lg my-4 color-main">Hard Drivers</p>
        </div>
        <div class="col-span-12 md:col-span-3 flex flex-col items-center justify-center">
          <i class="fa-solid fa-photo-film color-main text-4xl"></i>
          <p class="text-lg my-4 color-main">Hard Drivers</p>
        </div>
      </div>
    </div>

    <div class="md:col-span-5 col-span-12 md:col-start-9 rounded-[40px] overflow-hidden flex items-center justify-center">
      <img src="../../assets/new_assets/secure_destruction.jpg" width="100" class="shadow-2xl w-full object-contain rounded-[40px]" alt="" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>