<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',

};

</script>

<style>/*! elementor - v3.17.0 - 01-10-2023 */
.text-main-color {
  color: #272727 !important;
}
.bg-main{
  background-color: #BC0000 !important;
}
.color-main{
  color: #BC0000 !important;
}
.bg-lanier{
  background: linear-gradient(to top, #FFFFFF ,rgba(255,174,174,0.18),#FFFFFF);
}
.elementor-widget-divider {
  --divider-border-style: none;
  --divider-border-width: 1px;
  --divider-color: #0c0d0e;
  --divider-icon-size: 20px;
  --divider-element-spacing: 10px;
  --divider-pattern-height: 24px;
  --divider-pattern-size: 20px;
  --divider-pattern-url: none;
  --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
  display: flex
}

.elementor-widget-divider .elementor-divider__text {
  font-size: 15px;
  line-height: 1;
  max-width: 95%
}

.elementor-widget-divider .elementor-divider__element {
  margin: 0 var(--divider-element-spacing);
  flex-shrink: 0
}

.elementor-widget-divider .elementor-icon {
  font-size: var(--divider-icon-size)
}

.elementor-widget-divider .elementor-divider-separator {
  display: flex;
  margin: 0;
  direction: ltr
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator, .elementor-widget-divider--view-line_text .elementor-divider-separator {
  align-items: center
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator:after, .elementor-widget-divider--view-line_icon .elementor-divider-separator:before, .elementor-widget-divider--view-line_text .elementor-divider-separator:after, .elementor-widget-divider--view-line_text .elementor-divider-separator:before {
  display: block;
  content: "";
  border-bottom: 0;
  flex-grow: 1;
  border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator > .elementor-divider__svg:first-of-type {
  flex-grow: 0;
  flex-shrink: 100
}

.elementor-widget-divider--element-align-left .elementor-divider-separator:before {
  content: none
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
  margin-left: 0
}

.elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator > .elementor-divider__svg:last-of-type {
  flex-grow: 0;
  flex-shrink: 100
}

.elementor-widget-divider--element-align-right .elementor-divider-separator:after {
  content: none
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
  margin-right: 0
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
  border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
  --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator, .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after, .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before, .elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
  width: 100%;
  min-height: var(--divider-pattern-height);
  -webkit-mask-size: var(--divider-pattern-size) 100%;
  mask-size: var(--divider-pattern-size) 100%;
  -webkit-mask-repeat: var(--divider-pattern-repeat);
  mask-repeat: var(--divider-pattern-repeat);
  background-color: var(--divider-color);
  -webkit-mask-image: var(--divider-pattern-url);
  mask-image: var(--divider-pattern-url)
}

.elementor-widget-divider--no-spacing {
  --divider-pattern-size: auto
}

.elementor-widget-divider--bg-round {
  --divider-pattern-repeat: round
}

.rtl .elementor-widget-divider .elementor-divider__text {
  direction: rtl
}

.e-con-inner > .elementor-widget-divider, .e-con > .elementor-widget-divider {
  width: var(--container-widget-width, 100%);
  --flex-grow: var(--container-widget-flex-grow)
}

.elementor-widget-image-box .elementor-image-box-content {
  width: 100%
}

@media (min-width: 768px) {
  .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper, .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
    display: flex
  }

  .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
    text-align: right;
    flex-direction: row-reverse
  }

  .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
    text-align: left;
    flex-direction: row
  }

  .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
    margin: auto
  }

  .elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper {
    align-items: flex-start
  }

  .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
    align-items: center
  }

  .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper {
    align-items: flex-end
  }
}

@media (max-width: 767px) {
  .elementor-widget-image-box .elementor-image-box-img {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 15px
  }
}

.elementor-widget-image-box .elementor-image-box-img {
  display: inline-block
}

.elementor-widget-image-box .elementor-image-box-title a {
  color: inherit
}

.elementor-widget-image-box .elementor-image-box-wrapper {
  text-align: center
}

.elementor-widget-image-box .elementor-image-box-description {
  margin: 0
}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
  background-color: #69727d;
  color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
  color: #69727d;
  border: 3px solid;
  background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
  margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
  width: 1em;
  height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block
}

.elementor-button.elementor-hidden, .elementor-hidden {
  display: none
}

.e-form__step {
  width: 100%
}

.e-form__step:not(.elementor-hidden) {
  display: flex;
  flex-wrap: wrap
}

.e-form__buttons {
  flex-wrap: wrap
}

.e-form__buttons, .e-form__buttons__wrapper {
  display: flex
}

.e-form__indicators {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 13px;
  margin-bottom: var(--e-form-steps-indicators-spacing)
}

.e-form__indicators__indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
  padding: 0 var(--e-form-steps-divider-gap)
}

.e-form__indicators__indicator__progress {
  width: 100%;
  position: relative;
  background-color: var(--e-form-steps-indicator-progress-background-color);
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  overflow: hidden
}

.e-form__indicators__indicator__progress__meter {
  width: var(--e-form-steps-indicator-progress-meter-width, 0);
  height: var(--e-form-steps-indicator-progress-height);
  line-height: var(--e-form-steps-indicator-progress-height);
  padding-right: 15px;
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  background-color: var(--e-form-steps-indicator-progress-color);
  color: var(--e-form-steps-indicator-progress-meter-color);
  text-align: right;
  transition: width .1s linear
}

.e-form__indicators__indicator:first-child {
  padding-left: 0
}

.e-form__indicators__indicator:last-child {
  padding-right: 0
}

.e-form__indicators__indicator--state-inactive {
  color: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2)
}

.e-form__indicators__indicator--state-inactive [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-inactive-secondary-color, #fff)
}

.e-form__indicators__indicator--state-inactive object, .e-form__indicators__indicator--state-inactive svg {
  fill: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2)
}

.e-form__indicators__indicator--state-active {
  color: var(--e-form-steps-indicator-active-primary-color, #39b54a);
  border-color: var(--e-form-steps-indicator-active-secondary-color, #fff)
}

.e-form__indicators__indicator--state-active [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-active-secondary-color, #fff)
}

.e-form__indicators__indicator--state-active object, .e-form__indicators__indicator--state-active svg {
  fill: var(--e-form-steps-indicator-active-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed {
  color: var(--e-form-steps-indicator-completed-secondary-color, #fff)
}

.e-form__indicators__indicator--state-completed [class*=indicator--shape-]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-completed-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed .e-form__indicators__indicator__label {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a)
}

.e-form__indicators__indicator--state-completed .e-form__indicators__indicator--shape-none {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
  background-color: initial
}

.e-form__indicators__indicator--state-completed object, .e-form__indicators__indicator--state-completed svg {
  fill: var(--e-form-steps-indicator-completed-secondary-color, #fff)
}

.e-form__indicators__indicator__icon {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  font-size: var(--e-form-steps-indicator-icon-size);
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px
}

.e-form__indicators__indicator__icon img, .e-form__indicators__indicator__icon object, .e-form__indicators__indicator__icon svg {
  width: var(--e-form-steps-indicator-icon-size);
  height: auto
}

.e-form__indicators__indicator__icon .e-font-icon-svg {
  height: 1em
}

.e-form__indicators__indicator__number {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px
}

.e-form__indicators__indicator--shape-circle {
  border-radius: 50%
}

.e-form__indicators__indicator--shape-square {
  border-radius: 0
}

.e-form__indicators__indicator--shape-rounded {
  border-radius: 5px
}

.e-form__indicators__indicator--shape-none {
  border: 0
}

.e-form__indicators__indicator__label {
  text-align: center
}

.e-form__indicators__indicator__separator {
  width: 100%;
  height: var(--e-form-steps-divider-width);
  background-color: #babfc5
}

.e-form__indicators--type-icon, .e-form__indicators--type-icon_text, .e-form__indicators--type-number, .e-form__indicators--type-number_text {
  align-items: flex-start
}

.e-form__indicators--type-icon .e-form__indicators__indicator__separator, .e-form__indicators--type-icon_text .e-form__indicators__indicator__separator, .e-form__indicators--type-number .e-form__indicators__indicator__separator, .e-form__indicators--type-number_text .e-form__indicators__indicator__separator {
  margin-top: calc(var(--e-form-steps-indicator-padding, 30px) / 2 - var(--e-form-steps-divider-width, 1px) / 2)
}

.elementor-field-type-hidden {
  display: none
}

.elementor-field-type-html {
  display: inline-block
}

.elementor-login .elementor-lost-password, .elementor-login .elementor-remember-me {
  font-size: .85em
}

.elementor-field-type-recaptcha_v3 .elementor-field-label {
  display: none
}

.elementor-field-type-recaptcha_v3 .grecaptcha-badge {
  z-index: 1
}

.elementor-button .elementor-form-spinner {
  order: 3
}

.elementor-form .elementor-button > span {
  display: flex;
  justify-content: center;
  align-items: center
}

.elementor-form .elementor-button .elementor-button-text {
  white-space: normal;
  flex-grow: 0
}

.elementor-form .elementor-button svg {
  height: auto
}

.elementor-form .elementor-button .e-font-icon-svg {
  height: 1em
}

.elementor-select-wrapper .select-caret-down-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  inset-inline-end: 10px;
  pointer-events: none;
  font-size: 11px
}

.elementor-select-wrapper .select-caret-down-wrapper svg {
  display: unset;
  width: 1em;
  aspect-ratio: unset;
  fill: currentColor
}

.elementor-select-wrapper .select-caret-down-wrapper i {
  font-size: 19px;
  line-height: 2
}

.elementor-select-wrapper.remove-before:before {
  content: "" !important
}

.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-] > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
  font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
  font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
  font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
  font-size: 59px
}
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}

</style>






