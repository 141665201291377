<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-5564304 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="5564304">
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-e0a9dab"
          data-element_type="column" data-id="e0a9dab">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-4a2b92b elementor-widget elementor-widget-image"
              data-element_type="widget" data-id="4a2b92b"
              data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img alt="" class="attachment-medium size-medium wp-image-414"
                   decoding="async" height="71" loading="lazy"
                   src="../../assets/new_assets/Secure%20Storage%20Solutions.png"
                   width="70" />
            </div>
          </div>
          <div
              class="elementor-element elementor-element-1933672 elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="1933672"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h4 class="elementor-heading-title elementor-size-default">
                Secure Storage <br/> Solutions
              </h4></div>
          </div>
          <div
              class="elementor-element elementor-element-dcb3672 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="dcb3672"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>Our advanced physical storage facilities prioritize security, keeping your documents safe and
                easily accessible.</p></div>
          </div>
        </div>
      </div>
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-520fd6c"
          data-element_type="column" data-id="520fd6c">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-37b5ba3 elementor-widget elementor-widget-image"
              data-element_type="widget" data-id="37b5ba3"
              data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img alt="" class="attachment-medium size-medium wp-image-429"
                   decoding="async" height="71" loading="lazy"
                   src="../../assets/new_assets/Dedicated%20Business%20Support.png" width="68"/>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-e4f7e24 elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="e4f7e24"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h4 class="elementor-heading-title elementor-size-default">
                Dedicated Business Support
              </h4></div>
          </div>
          <div
              class="elementor-element elementor-element-b373d25 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="b373d25"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>We offer hands-on support to help your business manage documents efficiently, saving you time
                and resources.
              </p></div>
          </div>
        </div>
      </div>
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-e93172b"
          data-element_type="column" data-id="e93172b">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-05d6720 elementor-widget elementor-widget-image"
              data-element_type="widget" data-id="05d6720"
              data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img alt="" class="attachment-medium size-medium wp-image-408"
                   decoding="async" height="71" loading="lazy"
                   src="../../assets/new_assets/Technology%20Driven%20Innovation.png" width="69"/>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-4463599 elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="4463599"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h4 class="elementor-heading-title elementor-size-default">Technology-Driven Innovation
              </h4></div>
          </div>
          <div
              class="elementor-element elementor-element-3b37a69 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="3b37a69"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>Harness the power of modern tech with our integrated systems, making document management
                smarter and faster.
              </p></div>
          </div>
        </div>
      </div>
      <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-bf39a3c"
          data-element_type="column" data-id="bf39a3c">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-1307a3a elementor-widget elementor-widget-image"
              data-element_type="widget" data-id="1307a3a"
              data-widget_type="image.default">
            <div class="elementor-widget-container">
              <img alt="" class="attachment-medium size-medium wp-image-410"
                   decoding="async" height="71" loading="lazy"
                   src="../../assets/new_assets/Comprehensive%20Archiving%20Solutions.png" width="69"/>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-31c6a9a elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="31c6a9a"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h4 class="elementor-heading-title elementor-size-default">
                Comprehensive Archiving Solutions
              </h4></div>
          </div>
          <div
              class="elementor-element elementor-element-252db53 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
              data-element_type="widget" data-id="252db53"
              data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <p>Seamlessly integrate physical and digital archiving for a complete, streamlined document
                management solution.</p></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
</script>