<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-cfde7a9 elementor-section-items-stretch elementor-section-content-bottom elementor-section-height-min-height elementor-section-boxed elementor-section-height-default"
      data-element_type="section" data-id="cfde7a9">
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c3cc2"
          data-element_type="column" data-id="b9c3cc2">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div style="  background-image: url('../../../wp-content/uploads/2023/03/mask.png') ;   background-size: cover;
 ;
 ;  background-position: center;">

          </div>
          <div
              class="elementor-element elementor-element-3d03f29 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-invisible elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="3d03f29"
              data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;,&quot;_animation_delay&quot;:100}"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <h1 class="elementor-heading-title elementor-size-default">Data Management Faster. Smarter. Safer.</h1>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-0e2b671 elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-invisible elementor-widget elementor-widget-heading"
              data-element_type="widget" data-id="0e2b671"
              data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;,&quot;_animation_delay&quot;:300}"
              data-widget_type="heading.default">
            <div class="elementor-widget-container">
              <div class="elementor-heading-title elementor-size-default" style="font-size: 24px !important;">
                Business’ top choice for document management—streamlining workflows, enhancing
                productivity, and securing your data with tailored solutions.
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-aee0413 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget-tablet__width-auto elementor-invisible elementor-widget elementor-widget-button"
              data-element_type="widget" data-id="aee0413"
              data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;,&quot;_animation_delay&quot;:400}"
              data-widget_type="button.default">
            <div class="elementor-widget-container">
              <div class="elementor-button-wrapper">
                <a class="elementor-button elementor-button-link elementor-size-sm"
                   href="#form">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text md:px-4 px-0">Schedule a Free Consultation</span>
		</span>
                </a>
              </div>
            </div>
          </div>
          <div
              class="vamtam-has-theme-widget-styles elementor-element elementor-element-fae6b0b elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-invisible elementor-widget elementor-widget-button"
              data-element_type="widget" data-id="fae6b0b"
              data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_tablet&quot;:&quot;none&quot;,&quot;_animation_delay&quot;:400}"
              data-widget_type="button.default">
            <div class="elementor-widget-container" >
              <div class="elementor-button-wrapper" >
                <a class="elementor-button elementor-button-link elementor-size-sm" style="background-color: #BE0000 !important"
                   href="/document-and-media-storage">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text md:px-12 px-0">Services </span>
		</span>
                </a>
              </div>
            </div>
          </div>
          <div
              class="elementor-element elementor-element-38546ad elementor-absolute elementor-widget elementor-widget-image"
              data-element_type="widget" data-id="38546ad"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
              data-widget_type="image.default">
            <div class="elementor-widget-container ">
              <img alt="" class="attachment-2048x2048 size-2048x2048 wp-image-151"
                   decoding="async"
                   loading="lazy"
                   sizes="(max-width: 1498px) 100vw, 1498px"
                   src="../../assets/new_assets/Document_Storage-min.jpg"
                   />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script setup>

</script>