<template>
  <div class="w-full">
    <p style="color: #272727" class="font-thin">{{title}}</p>
    <h1 style="color: #272727">
    {{pageName}}
    </h1>
  </div>
</template>
<script>
export default {
  props:['pageName' , 'title']
}
</script>