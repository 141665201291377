<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-6 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name page-name="Document & Media Storage"/>
        <p class="my-10">
          At EDS, we understand that every business generates a vast amount of critical documents and
          media, which, if not managed efficiently, can overwhelm your workspace and resources. That's
          where our Document and Media Storage services come to the rescue. We offer a
          comprehensive, secure, and cost-effective solution to safeguard your valuable records and media assets, enabling you to focus on what truly matters—your business's growth and
          success.
        </p>
        <p class="my-10">
          Our high-density records centers provide an efficient and organized solution for all types of
          business records. We expertly organize your documents and media, helping you reclaim your
          valuable workspace
        </p>
        <p class="my-10">
          We prioritize accessibility. Our services include 24/7 access ensuring that your critical
          information is always within reach. Retrieving the right document at the right time is crucial for
          your operations. EDS provides comprehensive indexing and retrieval services, allowing access
          to your records with precision and ease.
        </p>
      </div>
    </div>
  <div class="md:col-span-6  col-span-12 h-full ">
    <img src="../../assets/new_assets/Document_&_Media_Storage%20(1).jpeg" class="w-full shadow-2xl  h-full object-cover rounded-[40px]" alt="document image" />
  </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>