<template>
  <div>
    <app-header/>
    <page-header />
    <page-content />
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import PageHeader from "@/components/SecureBox/HeaderPage.vue";
import PageContent from "@/components/SecureBox/PageContent.vue";
export default {
  components:{PageContent, PageHeader, AppHeader,AppFooter},
}
</script>