<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-c5e7779 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="c5e7779">
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0e9eeae"
          data-element_type="column" data-id="0e9eeae">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
              class="elementor-element elementor-element-a9dcc6d elementor-widget elementor-widget-template"
              data-element_type="widget" data-id="a9dcc6d"
              data-widget_type="template.default">
            <div class="elementor-widget-container">
              <div class="elementor-template">
                <div class="elementor elementor-2951" data-elementor-id="2951"
                     data-elementor-post-type="elementor_library"
                     data-elementor-type="section">
                  <section
                      class="elementor-section elementor-top-section elementor-element elementor-element-0925d25 elementor-section-full_width elementor-section-content-space-between elementor-section-height-default elementor-section-height-default"
                      data-element_type="section"
                      data-id="0925d25"
                      data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}"
                      style="padding-top: 25px">
                    <div class="elementor-container elementor-column-gap-no">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3217a40"
                          data-element_type="column" data-id="3217a40">
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                              class="elementor-element elementor-element-879ddd0 elementor-widget elementor-widget-spacer"
                              data-element_type="widget"
                              data-id="879ddd0"
                              data-widget_type="spacer.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-spacer">
                                <div class="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                              class="elementor-element elementor-element-b710185 elementor-widget elementor-widget-heading"
                              data-element_type="widget"
                              data-id="b710185"
                              data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                      class="elementor-section elementor-top-section elementor-element elementor-element-f0244df elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                      data-element_type="section" data-id="f0244df"
                      data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                    <div class="elementor-container elementor-column-gap-no">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f9824f8"
                          data-element_type="column" data-id="f9824f8">
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <section
                              class="elementor-section elementor-inner-section elementor-element elementor-element-6bdb0c9 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                              data-element_type="section"
                              data-id="6bdb0c9">
                            <div class="elementor-container elementor-column-gap-no">
                              <div
                                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5e92879"
                                  data-element_type="column"
                                  data-id="5e92879">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                      class="elementor-element elementor-element-1c4415b elementor-widget elementor-widget-text-editor"
                                      data-element_type="widget"
                                      data-id="1c4415b"
                                      data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                     </div>
                                  </div>
                                  <div
                                      class="vamtam-has-theme-widget-styles elementor-element elementor-element-3cc6c7f elementor-widget elementor-widget-button"
                                      data-element_type="widget"
                                      data-id="3cc6c7f"
                                      data-widget_type="button.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-button-wrapper">
                                        <a class="elementor-button elementor-button-link elementor-size-sm"
                                           href="tel:18003568933">
						<span class="elementor-button-content-wrapper">
		</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
<!--                                  <div-->
<!--                                      class="elementor-element elementor-element-661fb38 elementor-widget elementor-widget-heading"-->
<!--                                      data-element_type="widget"-->
<!--                                      data-id="661fb38"-->
<!--                                      data-widget_type="heading.default">-->
<!--                                    <div class="elementor-widget-container">-->
<!--                                      <h5 class="elementor-heading-title elementor-size-default">-->
<!--                                        Benefits of-->
<!--                                        partnering with-->
<!--                                        EDS:</h5>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  <div-->
<!--                                      class="elementor-element elementor-element-8173d70 elementor-widget__width-auto elementor-icon-list&#45;&#45;layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"-->
<!--                                      data-element_type="widget"-->
<!--                                      data-id="8173d70"-->
<!--                                      data-widget_type="icon-list.default">-->
<!--                                    <div class="elementor-widget-container">-->
<!--                                      <link-->
<!--                                          href="../../wp-content/plugins/elementor/assets/css/widget-icon-list.min.css"-->
<!--                                          rel="stylesheet">-->
<!--                                      <ul class="elementor-icon-list-items">-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span-->
<!--                                              class="elementor-icon-list-text">• Customer-centric</span>-->
<!--                                        </li>-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span class="elementor-icon-list-text">• Reliable</span>-->
<!--                                        </li>-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span class="elementor-icon-list-text">• Competent</span>-->
<!--                                        </li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  <div-->
<!--                                      class="elementor-element elementor-element-53c75d0 elementor-widget__width-auto elementor-icon-list&#45;&#45;layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"-->
<!--                                      data-element_type="widget"-->
<!--                                      data-id="53c75d0"-->
<!--                                      data-widget_type="icon-list.default">-->
<!--                                    <div class="elementor-widget-container">-->
<!--                                      <ul class="elementor-icon-list-items">-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span class="elementor-icon-list-text">• Results-driven</span>-->
<!--                                        </li>-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span-->
<!--                                              class="elementor-icon-list-text">• Problem-solving </span>-->
<!--                                        </li>-->
<!--                                        <li class="elementor-icon-list-item">-->
<!--											<span class="elementor-icon-list-icon">-->
<!--							<i aria-hidden="true" class="vamtamtheme- vamtam-theme-check-box"></i>						</span>-->
<!--                                          <span class="elementor-icon-list-text">• Transparent</span>-->
<!--                                        </li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </div>-->
                                  <div
                                      class="elementor-element relative top-[-80px] elementor-element-89f0d85 elementor-widget elementor-widget-heading"
                                      data-element_type="widget"
                                      data-id="89f0d85"
                                      data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                      <h2>
                                        Contact Us

                                      </h2>
                                        <p class="mt-10">We&#8217;re happy
                                          to answer any
                                          questions you
                                          may have and
                                          help you
                                          determine which
                                          of our services
                                          best fit your
                                          needs.</p></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9a1c1b5"
                                  data-element_type="column"
                                  data-id="9a1c1b5"
                                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div id="form"
                                       class="elementor-element elementor-element-45d8aa6 elementor-widget elementor-widget-heading"
                                       data-element_type="widget"
                                       data-id="45d8aa6"
                                       data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                      <h5 class="elementor-heading-title elementor-size-default">
                                        Schedule a Free
                                        Consultation</h5>
                                    </div>
                                  </div>
                                  <div
                                      class="elementor-element elementor-element-ae7df5c elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                                      data-element_type="widget"
                                      data-id="ae7df5c"
                                      data-widget_type="icon.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-icon-wrapper">
                                        <div class="elementor-icon">
                                          <i aria-hidden="true"
                                             class="vamtamtheme- vamtam-theme-arrow-il"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                      class="elementor-element elementor-element-6706deb elementor-widget__width-inherit elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-element_type="widget"
                                      data-id="6706deb"
                                      data-widget_type="divider.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                      class="elementor-element elementor-element-f361fbd elementor-button-align-start elementor-widget elementor-widget-form"
                                      data-element_type="widget"
                                      data-id="f361fbd"
                                      data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}"
                                      data-widget_type="form.default">
                                    <div class="elementor-widget-container">
                                      <form id="contactForm" @submit="submitForm"
                                            name="Schedule a Free Consultation">
                                        <input name="post_id"
                                               type="hidden"
                                               value="2951"/>
                                        <input name="form_id"
                                               type="hidden"
                                               value="f361fbd"/>
                                        <input name="referer_title"
                                               type="hidden"
                                               value="EDS"/>

                                        <input name="queried_id"
                                               type="hidden"
                                               value="146"/>

                                        <div
                                            class="elementor-form-fields-wrapper elementor-labels-above">
                                          <div
                                              class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-form_free_consultation_first_name elementor-col-50 elementor-field-required">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_consultation_first_name">
                                              First
                                              name </label>
                                            <input id="form-field-form_free_consultation_first_name"
                                                   aria-required="true"
                                                   class="elementor-field elementor-size-sm  elementor-field-textual"
                                                   name="form_fields[form_free_consultation_first_name]"
                                                   required="required"
                                                   size="1"
                                                   type="text">
                                          </div>
                                          <div
                                              class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-form_free_consultation_last_name elementor-col-50 elementor-field-required">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_consultation_last_name">
                                              Last
                                              name </label>
                                            <input id="form-field-form_free_consultation_last_name"
                                                   aria-required="true"
                                                   class="elementor-field elementor-size-sm  elementor-field-textual"
                                                   name="form_fields[form_free_consultation_last_name]"
                                                   required="required"
                                                   size="1"
                                                   type="text">
                                          </div>
                                          <div
                                              class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-form_free_consultation_company_name elementor-col-100">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_consultation_company_name">
                                              Company
                                              /
                                              Organization </label>
                                            <input id="form-field-form_free_consultation_company_name"
                                                   class="elementor-field elementor-size-sm  elementor-field-textual"
                                                   name="form_fields[form_free_consultation_company_name]"
                                                   required
                                                   size="1"
                                                   type="text">
                                          </div>
                                          <div
                                              class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-form_free_email elementor-col-100 elementor-field-required">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_email">
                                              Company
                                              email </label>
                                            <input id="form-field-form_free_email"
                                                   aria-required="true"
                                                   class="elementor-field elementor-size-sm  elementor-field-textual"
                                                   name="form_fields[form_free_email]"
                                                   required="required"
                                                   size="1"
                                                   type="email">
                                          </div>
                                          <div
                                              class="elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-form_free_consultation_phone elementor-col-100">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_consultation_phone">
                                              Phone </label>
                                            <input id="form-field-form_free_consultation_phone"
                                                   class="elementor-field elementor-size-sm  elementor-field-textual"
                                                   name="form_fields[form_free_consultation_phone]"
                                                   pattern="[0-9()#&amp;+*-=.]+"
                                                   size="1"
                                                   required
                                                   title="Only numbers and phone characters (#, -, *, etc) are accepted."
                                                   type="tel">

                                          </div>
                                          <div
                                              class="elementor-field-type-select elementor-field-group elementor-column elementor-field-group-form_free_topic elementor-col-100">
                                            <label class="elementor-field-label"
                                                   for="form-field-form_free_topic">
                                              How
                                              Can
                                              We
                                              Help
                                              You? </label>
                                            <div
                                                class="elementor-field elementor-select-wrapper remove-before ">
                                              <div class="select-caret-down-wrapper">
                                                <svg aria-hidden="true"
                                                     class="e-font-icon-svg e-eicon-caret-down"
                                                     viewBox="0 0 571.4 571.4"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                      d="M571 393Q571 407 561 418L311 668Q300 679 286 679T261 668L11 418Q0 407 0 393T11 368 36 357H536Q550 357 561 368T571 393Z"></path>
                                                </svg>
                                              </div>
                                              <select id="form-field-form_free_topic"
                                                      required
                                                      class="elementor-field-textual elementor-size-sm"
                                                      name="form_fields[form_free_topic]">
                                                <option value=""></option>
                                                <option value="Document and Media Storage">
                                                  Document and Media Storage
                                                </option>
                                                <option value="Vault Storage">
                                                  Vault Storage
                                                </option>
                                                <option value="Digital Archiving">
                                                  Digital Archiving
                                                </option>
                                                <option value="Asset Management">
                                                  Asset Management
                                                </option>
                                                <option value="Secure Destruction">
                                                  Secure Destruction
                                                </option>
                                                <option value="Data Entry">
                                                  Data Entry
                                                </option>
                                                <option value="Customized Solutions">
                                                  Customized Solutions
                                                </option>
                                                <option value="Secure Box">
                                                  Secure Box
                                                </option>
                                                <option value="Other">
                                                  Other
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div
                                              class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-message elementor-col-100">
                                            <label class="elementor-field-label"
                                                   for="form-field-message">
                                              Message </label>
                                            <textarea
                                                id="form-field-message"
                                                required
                                                class="elementor-field-textual elementor-field  elementor-size-sm"
                                                name="form_fields[message]"
                                                placeholder="To better assist you, please describe how we can help..."
                                                rows="4"></textarea>
                                          </div>
                                          <div class="elementor-field-type-text">
                                            <input id="form-field-field_13272ad"
                                                   class="elementor-field elementor-size-sm "
                                                   name="form_fields[field_13272ad]"
                                                   size="1"
                                                   style="display:none !important;"
                                                   type="text">
                                          </div>
                                          <div
                                              class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                                            <button class="elementor-button elementor-size-sm"
                                                    style="outline: none ; border: none"
                                                    type="submit">
						<span>
															<span class=" elementor-button-icon">
																										</span>
																						<span id="submit-button" class="elementor-button-text">Submit</span>
													</span>
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>
export default {
  methods:{
    submitForm(event){
        document.getElementById('submit-button').innerText = 'Loading...'
        event.preventDefault(); // Prevent the default form submission behavior
        let data = new FormData();
        data.append('first_name', document.getElementById("form-field-form_free_consultation_first_name").value)
        data.append('last_name', document.getElementById("form-field-form_free_consultation_last_name").value)
        data.append('company', document.getElementById("form-field-form_free_consultation_company_name").value)
        data.append('email', document.getElementById("form-field-form_free_email").value)
        data.append('phone', document.getElementById("form-field-form_free_consultation_phone").value)
        data.append('help', document.getElementById("form-field-form_free_topic").value)
        data.append('message', document.getElementById("form-field-message").value)

        fetch("https://send-email.eds-egypt.com/api/send-email", {
          method: "POST",
          body: data
        })
            .then(response => response.text())
            .then(() => {
              alert('Form submitted successfully!')
              location.reload()
            })
            .catch(error => {
              console.error("Error submitting form:", error);
              alert("Error submitting form. Please try again later.");
            });

    }
  }
}
</script>

<style>
p{
  padding: 0 !important;
  text-align: start!important;
}
</style>