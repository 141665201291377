<template>
  <div>
  <app-header/>
    <div id="page" class="main-container">
      <div id="main-content">
        <div id="main" class="vamtam-main layout-full" role="main">
          <div class="page-wrapper">
            <article id="post-146" class="full post-146 page type-page status-publish hentry">
              <div class="page-content clearfix the-content-parent">
                <div class="elementor elementor-146" data-elementor-id="146" data-elementor-post-type="page"
                     data-elementor-type="wp-page">
                  <opening-component/>
                  <section
                      class="elementor-section elementor-top-section elementor-element elementor-element-1f4e98c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-element_type="section" data-id="1f4e98c">
                    <div class="elementor-container elementor-column-gap-no">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7020a69"
                          data-element_type="column" data-id="7020a69">
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                              class="elementor-element elementor-element-1e79972 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                              data-element_type="widget" data-id="1e79972"
                              data-widget_type="divider.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-divider">
                                  <span class="elementor-divider-separator">
                                        </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <records-component />
                  <what-we-do/>
                  <four-sections/>
                  <section
                      data-aos="fade-right" data-aos-duration="1000"
                      class="elementor-section elementor-top-section elementor-element elementor-element-dcdb079 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-element_type="section" data-id="dcdb079">
                    <div class="elementor-container elementor-column-gap-no">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90af185"
                          data-element_type="column" data-id="90af185">
                        <div class="elementor-widget-wrap elementor-element-populated">
                          <div
                              class="vamtam-has-theme-widget-styles elementor-element elementor-element-e544626 elementor-widget elementor-widget-global elementor-global-607 elementor-widget-button"
                              data-element_type="widget" data-id="e544626"
                              data-widget_type="button.default">
                            <div class="elementor-widget-container">
                              <div class="elementor-button-wrapper">
                                <a class="elementor-button elementor-button-link elementor-size-sm"
                                   href="/about-us">
						<span class="elementor-button-content-wrapper">
						<h5 class="elementor-button-text">About EDS</h5>
		</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <solutions-eds/>
                  <value-missions/>
                  <commitment-component class="pt-20"/>
                  <form-component/>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    <app-footer/>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import OpeningComponent from "@/components/HomePage/OpeningComponent.vue";
import RecordsComponent from "@/components/HomePage/RecordsComponent.vue";
import WhatWeDo from "@/components/HomePage/WhatWeDo.vue";
import FourSections from "@/components/HomePage/FourSections.vue";
import SolutionsEds from "@/components/HomePage/SolutionsEds.vue";
import CommitmentComponent from "@/components/HomePage/CommitmentComponent.vue";
import ValueMissions from "@/components/HomePage/ValueMissions.vue";
import FormComponent from "@/components/SharedComponents/FormComponent.vue";

export default {
  components: {
    FormComponent,
    ValueMissions,
    CommitmentComponent,
    SolutionsEds, FourSections, WhatWeDo, RecordsComponent, OpeningComponent, AppFooter, AppHeader
  },
}
</script>
