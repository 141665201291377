<template>
  <div class="md:p-20 p-4 pl-0 w-full grid grid-cols-12 bg-lanier">
    <div class="col-span-12 grid grid-cols-12">
      <div class="md:col-span-7 col-span-12 flex flex-col md:p-10 p-3 ">
        <p class="text-main-color">// Physical Archiving & Storage</p>
        <h1 style="font-size: 76px" class="text-main-color my-10">
          Our Physical
          Storage Facilities
        </h1>
        <p class="text-gray-500"  style="font-size: 20px">
          We use O’Neil Solutions for record management, the
          world’s leading software for commercial record centers.
        </p>
      </div>
      <div class="md:col-span-5 col-span-12 rounded-3xl overflow-hidden">
        <img src="../../assets/new_assets/storage.jpg" class="w-full h-full object-cover" alt="" />
      </div>
    </div>
    <div class="col-span-12 grid grid-cols-12 bg-white md:m-20 m-4  rounded-3xl shadow-md">
      <div class="md:col-span-4 col-span-12 md:px-24 px-4 md:py-10 py-4">
        <div class="w-full">

          <p class="my-2" style="color: #737373">
            <span class="font-bold text-color-main ">Location:</span> Sadat City
          </p>
        </div>
        <div class="w-40 my-4 py-[.5px] bg-gray-400 ">

        </div>
        <div class="w-full">
          <h4 style="color: #737373">
           Features
          </h4>
          <p class="mt-2" style="color: #737373">
            • Power Backup Systems
          </p>
          <p style="color: #737373">
            • Automated Workflows
          </p>
          <p style="color: #737373">
            • A purpose-built facility
          </p>
        </div>
      </div>
      <div class="md:col-span-4 col-span-12 md:px-24 px-4 md:py-10 py-4">
        <h4 style="color: #737373 " class="my-2">
          Superior Storage
          Conditions
        </h4>
        <p style="color: #737373" class="my-8">
          fire protection, pest control, and
          climate regulation — ensure the
          longevity of vital records today.
        </p>
        <button class="w-40 bg-main" style="border-radius: 22px" @click="goFire">
          Know More
        </button>
      </div>
      <div class="md:col-span-4 col-span-12 bg-main rounded-3xl p-10 md:p-10 py-4">
        <h4 style="color: white">
          Security
        </h4>
        <p style="color: white" class="my-2">• Secure Vault Room Access</p>
        <p style="color: white" class="my-2">• 24/7 CCTV & night vision cameras</p>
        <p style="color: white" class="my-2">• On-ground security staff</p>
        <p style="color: white" class="my-2">• Access control and authorization
          level protocols</p>
        <p style="color: white" class="my-2">• Secure transit areas for loading
          and unloading</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
methods:{
  goFire(){
    this.$router.push({name:'FireProtection'})
  }
}
}
</script>