<template>
  <div>
    <app-header/>
    <solution-header/>
    <services-products/>
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import ServicesProducts from "@/components/ServicesProducts/ServicesProducts.vue";
import SolutionHeader from "@/components/SharedComponents/SolutionHeader.vue";
export default {
  components:{SolutionHeader, AppHeader,AppFooter,ServicesProducts},
}
</script>