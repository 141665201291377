<template>
  <div data-aos="fade-right" data-aos-duration="1000">
    <section
        class="elementor-section elementor-top-section elementor-element elementor-element-a665968 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-element_type="section" data-id="a665968">
      <div class="elementor-container elementor-column-gap-no">
        <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-73213ce"
            data-element_type="column" data-id="73213ce"
            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
          <div class="elementor-widget-wrap elementor-element-populated">
            <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-a9d85b8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-element_type="section" data-id="a9d85b8"
                data-settings="{&quot;background_motion_fx_devices&quot;:[]}">
              <div class="elementor-container elementor-column-gap-no">
                <div
                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-cdbe00b"
                    data-element_type="column" data-id="cdbe00b"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;background_motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;background_motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:3,&quot;sizes&quot;:[]},&quot;background_motion_fx_devices&quot;:[&quot;desktop&quot;],&quot;background_motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}}}">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                        class="elementor-element elementor-element-6092203 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                        data-element_type="widget" data-id="6092203"
                        data-widget_type="heading.default"
                        style="background-color: #BE0000 !important;">
                      <div class="elementor-widget-container">
                        <div class="elementor-heading-title elementor-size-default">
                          What we do
                        </div>
                      </div>
                    </div>
                    <div
                        class="elementor-element elementor-element-b265099 elementor-widget elementor-widget-spacer"
                        data-element_type="widget" data-id="b265099"
                        data-widget_type="spacer.default">
                      <div class="elementor-widget-container">
                        <div class="elementor-spacer">
                          <div class="elementor-spacer-inner"></div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 class=" text-white" style="font-size: 28px; color: white !important; font-weight: normal !important;">
                        EDS delivers robust document and data management solutions, offering secure storage and
                        dedicated support to help organizations protect sensitive information, ensure compliance, and
                        optimize costs.</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script setup>
</script>