<template>
  <div class="w-full grid grid-cols-12 md:p-20 p-10 bg-lanier">
    <div class="md:col-span-7 col-span-12 grid grid-cols-12">
      <div class="col-span-12 md:px-20 px-3 md:pt-0 pt-10">
        <page-name title="// OUR SOLUTIONS" page-name="Customized Software Solutions"/>
        <p class="my-10">
          At EDS, we understand that your business and challenges are unique.
          That’s why we offer fully customized software applications designed to
          meet your specific needs, streamline operations, and drive growth.
        </p>
        <p class="my-10">
          Whether you need a tailored CRM system, process automation, or
          specialized data management tools, our expert team is here to
          develop and implement innovative solutions that align perfectly with
          your objectives. We don’t just create software—we craft technology
          that enhances productivity and ensures your business stays ahead of
          the competition.
        </p>
      </div>
    </div>

    <div class="md:col-span-5  col-span-12">
      <img src="../../assets/new_assets/Customized_Software_Solutions.jpg" class="w-full shadow-2xl  rounded-[40px]" alt="customized image" />
    </div>
  </div>
</template>
<script>
import PageName from "@/components/SharedComponents/PageName.vue";

export default {
  components: {PageName}
}
</script>