<template>
  <div class="w-full grid grid-cols-12 bg-lanier  md:p-20 p-10">
    <div class="w-full col-span-12 mx-20">
      <h2 style="color: #272727">
        Secure Box
      </h2>
    </div>
    <div class="md:col-span-7 col-span-12   md:mx-20 mx-3">
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Security:</span> Secure Boxes feature lockable lids to prevent
        unauthorized access to sensitive information.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Durability:</span> Built from strong materials for secure storage and
        transport.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Confidential Waste:</span> Clearly labeled for confidential disposal,
        ensuring easy separation from regular waste.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Compliance:</span>  Helps organizations meet data protection and
        privacy regulations, preventing data breaches.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Convenience:</span> : Designed for easy use and available in
        different sizes for office placement.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Eco-Friendly:</span> Contents are securely shredded and recycled,
        supporting sustainability.
      </p>
      <p class="my-10 text-lg">
        <span class="font-bold text-[#BC0000]">Regular Disposal:</span>  Scheduled collection ensures timely,
        secure destruction of confidential waste.
      </p>
    </div>
    <div class="md:col-span-5 col-span-12 flex justify-end  pl-0">
      <img class="rounded-3xl shadow-2xl" src="../../assets/new_assets/EDS_Secure_Box.png" />
    </div>
  </div>
</template>
<script setup>
</script>