<template>
  <div>
    <app-header/>
    <documents-media/>
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import DocumentsMedia from "@/components/DocumentsMedia/DocumentsMedia.vue";
export default {
  components:{DocumentsMedia, AppHeader,AppFooter},
}
</script>