<template>
  <div class="w-full h-[400px] bg-[#ac0000] text-white grid grid-cols-12 items-center overflow-hidden">
    <div style="color: white ; font-size: 72px" class="md:col-span-7 col-span-12 md:pl-[100px] pl-10 pb-10 md:pb-0">
      <p class="text-white font-thin text-2xl mx-2">// JOIN OUR TEAM</p>
      Careers
    </div>
    <div class="md:col-span-5 col-span-12 h-12  flex items-center">
      <img src="../../assets/new_assets/carr.jpg" class="w-full object-contain" alt="SolutionHeader" />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>