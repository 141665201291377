
import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/IndexView.vue';
import ServicesProducts from "./views/ServicesProducts.vue";
import DocumentMediaStorage from "./views/document-media.vue";
import DataEntry from "./views/DataEntry.vue";
import DigitalArchiving from "./views/DigitalArchiving.vue";
import SecureDestruction from "./views/SecureDestruction.vue";
import AssetManagement from "./views/AssetTrackingServices.vue";
import VaultStorage from "./views/VaultRoom.vue";
import SecureBox from "./views/SecureBox.vue";
import CustomizedSoftwareSolutions from "./views/CustomizedSoftwareApplications.vue";
import SustainabilityPage from "./views/SustainabilityPage.vue";
import ContactUs from "./views/ContactsPage.vue";
import CareersPage from "./views/CareersPage.vue";
import AboutUs from "./views/AboutUs.vue";
import Facility from "./views/OurFacility.vue"
import page404 from "./views/404.vue";
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Document Management Company | EDS',
      description: 'Driving the evolution of data and document management, promising unmatched convenience, security, and intelligence for your business.',
      canonical: 'https://eds-egypt.com/'
    },
  },
  {
    path: '/solutions',
    name: 'ServicesProducts',
    component: ServicesProducts,
    meta: {
      title: 'Document Management Solutions | EDS',
      description: 'We provide a wide range of document management services, from advanced digital archiving to efficient document retrieval systems.',
      canonical: 'https://eds-egypt.com/solutions'
    },
  },
  {
    path: '/document-and-media-storage',
    name: 'DocumentMediaStorage',
    component: DocumentMediaStorage,
    description: 'We offer a comprehensive, secure, and cost-effective solution to safeguard your valuable records and media assets.',
    meta: {
      title: 'Document & Media Storage | EDS',
      description: 'We offer a comprehensive, secure, and cost-effective solution to safeguard your valuable records and media assets.',
      canonical: 'https://eds-egypt.com/document-and-media-storage'

    },
  },
  {
    path: '/vault-storage',
    name: 'VaultStorage',
    component: VaultStorage,
    description: 'We provide a safe, secure, reliable, and compliant solution for all your document storage needs.',
    meta: {
      title: 'Vault Storage | EDS',
      description: 'We provide a safe, secure, reliable, and compliant solution for all your document storage needs.',
      canonical: 'https://eds-egypt.com/vault-storage'
    },
  },
  {
    path: '/digital-archiving',
    name: 'DigitalArchiving',
    component: DigitalArchiving,
    description:'We help you transform paper records into electronic copies for easy access with our digital scanning services.',
    meta: {
      title: 'Digital Archiving | EDS',
      description: 'We help you transform paper records into electronic copies for easy access with our digital scanning services.',
      canonical: 'https://eds-egypt.com/digital-archiving'

    },
  },
  {
    path: '/asset-management-services',
    name: 'AssetManagement',
    component: AssetManagement,
    description: 'Optimize asset inventory for efficiency and cost-effectiveness by refreshing, returning, or removing assets as needed.',

    meta: {
      title: 'Asset Management | EDS',
      description: 'Optimize asset inventory for efficiency and cost-effectiveness by refreshing, returning, or removing assets as needed.',
      canonical: 'https://eds-egypt.com/asset-management-services'

    },
  },
  {
    path: '/secure-destruction',
    name: 'SecureDestruction',
    component: SecureDestruction,
    description: 'Secure and eco-friendly destruction of various records on-site and offsite to maintain data confidentiality.',
    meta: {
      title: 'Secure Destruction | EDS',
      description: 'Secure and eco-friendly destruction of various records on-site and offsite to maintain data confidentiality.',
      canonical: 'https://eds-egypt.com/secure-destruction'

    },
  },
  {
    path: '/data-entry',
    name: 'DataEntry',
    component: DataEntry,
    description: 'Accurate, efficient, and scalable data capture, entry, and processing for projects of all sizes.',
    meta: {
      title: 'Data Entry | EDS',
      description: 'Accurate, efficient, and scalable data capture, entry, and processing for projects of all sizes.',
      canonical: 'https://eds-egypt.com/data-entry'

    },
  },
  {
    path: '/customized-software-solutions',
    name: 'CustomizedSoftwareSolutions',
    description: 'Implement custom innovative solutions that align perfectly with your objectives.',
    component: CustomizedSoftwareSolutions,
    meta: {
      title: 'Customized Software Solutions | EDS',
      description: 'Implement custom innovative solutions that align perfectly with your objectives.',
      canonical: 'https://eds-egypt.com/customized-software-solutions'

    },
  },
  {
    path: '/facility',
    name: 'Facility',
    component: Facility,
    meta: {
      title: 'Our Facility | EDS',
      description: 'Purpose-built facility with fire protection, pest control, and climate regulation—ensure the longevity of vital records today.',
      canonical: 'https://eds-egypt.com/facility'

    },
  },
  { path: '/secure-box',
    name: 'SecureBox',
    component: SecureBox,
    meta: {
      title: 'Secure Box | EDS',
      description: 'Secure and eco-friendly destruction of various records on-site and offsite to maintain data confidentiality.',
      canonical: 'https://eds-egypt.com/secure-box'
    },
  },

  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: 'About Us | EDS',
      description: 'The most mature and equipped document and data management brand in Egypt.',
      canonical: 'https://eds-egypt.com/about-us'

    },
  },
  {
    path: '/careers',
    name: 'CareersPage',
    component: CareersPage,
    meta: {
      title: 'Careers | EDS',
      description: 'Discover exciting career opportunities in the world of data management and grow with a dynamic team dedicated to innovation and success.',
      canonical: 'https://eds-egypt.com/careers'
    },
  },
  {
    path: '/sustainability',
    name: 'SustainabilityPage',
    component: SustainabilityPage,
    meta: {
      title: 'Sustainability at EDS',
      description: 'Empowering businesses with eco-friendly data and document management solutions that drive meaningful change for future generations.',
      canonical: 'https://eds-egypt.com/sustainability'

    },
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: 'Contact Us | EDS',
      description: "Get in touch with EDS today—we're here to help and answer any questions you may have.",
      canonical: 'https://eds-egypt.com/contact-us'

    },
  },
  { path: '/:pathMatch(.*)*', component: page404 },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // Update the document title
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Update the meta description
  if (to.meta.description) {
    let descriptionMeta = document.querySelector('meta[name="description"]');
    if (!descriptionMeta) {
      descriptionMeta = document.createElement('meta');
      descriptionMeta.name = 'description';
      document.head.appendChild(descriptionMeta);
    }
    descriptionMeta.content = to.meta.description;
  }

  next();
});
router.afterEach((to) => {
  // Track page view manually (optional, if needed)
  this.$gtag.event('page_view', {
    event_category: 'Navigation',
    event_label: to.path,
    value: 1,
  });
});


export default router;
