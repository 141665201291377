<template>
  <div class="shadow">
    <nav class="nav-p" style="z-index: 999">
      <div class="nav-container">
        <div class="logo cursor-pointer" @click="goHome">
          <img alt="" src="../../wp-content/uploads/2023/03/logo-197-106.png" style="width: 130px; margin-left: 35%"/>
        </div>
        <div class="menu">
          <ul>
            <li class="nav-item"><a href="/">Home</a></li>
            <li class="nav-item"><a href="/solutions">Solutions</a></li>
            <li class="nav-item"><a href="/facility">Facility</a></li>
            <li class="nav-item ">
              <a href="#">
                <div class="flex">
                  <p>Company</p>
                  <img alt="" src="../../assets/angle-down-solid.svg" class="mx-1" style="width: 10px"/>

                </div>

              </a>
              <ul class="dropdown absolute">
                <li><a href="/about-us" style="border-radius: 5px 0 0 0">About Us</a></li>
                <li class="nav-item"><a href="/careers">Careers</a></li>
                <li><a href="/sustainability" style="border-radius: 0 0 5px 5px">Sustainability</a></li>
              </ul>
            </li>
            <li class="nav-item"><a href="/contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div class="mobile-menu-icon" style="font-size: 40px; margin-right: 10%" @click="toggleMobileMenu">&#9776;
        </div>
      </div>
      <div class="mobile-menu">
        <ul>
          <li class="nav-item"><a href="/">Home</a></li>
          <li class="nav-item"><a href="/solutions">Solutions</a></li>
          <li class="nav-item"><a href="/facility">Facility</a></li>
          <li class="nav-item"><a href="#">Company</a></li>
          <li class="sub-item"><a href="/about-us">About Us</a></li>
          <li class="sub-item"><a href="/careers">Careers</a></li>
          <li class="sub-item"><a href="/sustainability">Sustainability</a></li>
          <li class="nav-item"><a href="/contact-us">Contact Us</a></li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  methods: {
    goHome(){
      this.$router.push({name:'Home'})
    },
    toggleMobileMenu() {
      const mobileMenu = document.querySelector('.mobile-menu');
      if (mobileMenu.style.display === 'block') {
        mobileMenu.style.display = 'none';
      } else {
        mobileMenu.style.display = 'block';
      }
    }
  }
}
</script>
