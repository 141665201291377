<template>
  <div>
    <app-header/>
    <page-content />
    <app-footer/>
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import PageContent from "@/components/CustomizeSoftware/PageContent.vue";
export default {
  components:{PageContent, AppHeader,AppFooter},
}
</script>