<template>
  <section  class="services" style="padding-bottom: 70px ;  background: linear-gradient(to top, #FFFFFF ,rgba(255,174,174,0.18), #FFFFFF,#FFFFFF); /* Dark mystical gradient */">
    <div class="w-full text-center">
      <h1 style="padding-bottom:20px; font-size: 42px ; margin: 50px 0 ; color: #505050">Document Management <span style="color: #ac0000">Solutions & Beyond</span></h1>
    </div>
    <div class="w-full grid grid-cols-12 gap-6 md:p-20 p-0">
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/document-and-media-storage">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">1</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Document and Media Storage</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray"  class="mt-6">Secure and cost-effective solutions
            for storing business records and
            media assets, helping you focus on
            business growth.</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/vault-storage">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">2</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Vault Storage</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray"  class="mt-6">Provide a safe, secure, reliable, and
            compliant solution for all your
            document storage needs.</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/digital-archiving">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">3</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Digital Archiving</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Transform paper records into
            electronic copies for easy access
            with our digital scanning services.</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/asset-management-services">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">4</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Asset Management</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Optimize asset inventory for
            efficiency and cost-effectiveness by
            refreshing, returning, or removing
            assets as needed.</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/secure-destruction">
        <div class="w-full flex items-center justify-center  flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">5</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Secure Destruction</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Secure and eco-friendly destruction
            of various records on-site and offsite to maintain data confidentiality</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/data-entry">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">6</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Data Entry</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Accurate, efficient, and scalable
            data capture, entry, and processing
            for projects of all sizes.</p>
        </div>
      </a>
      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/customized-software-solutions">
        <div class="w-full flex items-center justify-center flex-col text-center">
          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">7</div>
          <h4 class="text-[#BE0000]" style="color: #BE0000">Customized Solutions</h4>
        </div>
        <div class="card-back">
          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Implement custom innovative
            solutions that align perfectly with
            your objectives.</p>
        </div>
      </a>
<!--      <a class="md:col-span-3 col-span-12 shadow-md rounded-lg md:p-4 p-2 py-20 bg-white" href="/secure-box">-->
<!--        <div class="w-full flex items-center justify-center flex-col text-center">-->
<!--          <div class="icon bg-gray-300 text-white h-20 w-20 rounded-full flex items-center justify-center my-3 text-3xl">8</div>-->
<!--          <h4 class="text-[#BE0000]" style="color: #BE0000">Secure Box</h4>-->
<!--        </div>-->
<!--        <div class="card-back">-->
<!--          <p style="text-align: center !important; padding: 0 20px; color: gray" class="mt-6">Secure Boxes are designed for collecting and disposing of confidential documents, ensuring-->
<!--            information security and compliance with privacy regulations.</p>-->
<!--        </div>-->
<!--      </a>-->
      <div class="md:col-span-3 col-span-12 shadow-md rounded-lg overflow-hidden ">
        <img src="../../assets/new_assets/solutionsImage.jpg" class="w-full h-full object-cover " alt="solution-image" />
      </div>
    </div>
  </section>
</template>
<script setup>
</script>

