<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-5f703b1 elementor-section-content-space-between elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-element_type="section" data-id="5f703b1"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div class="elementor-container elementor-column-gap-no">
      <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-076a446"
          data-element_type="column" data-id="076a446">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div>
            <div
                class="elementor-element elementor-element-385e06c elementor-widget__width-auto elementor-widget elementor-widget-heading"
                data-element_type="widget" data-id="385e06c"
                data-widget_type="heading.default">
              <div class="elementor-widget-container"
                   style="background-color: #BE0000 !important;">
                <div class="elementor-heading-title elementor-size-default">Our
                  values & mission
                </div>
              </div>
            </div>
          </div>
          <div class="colMedia" style="display:flex">
            <div class="wFullMedia" style="width: 100%;">
              <div style="width: 80%">
                <div
                    class="elementor-element elementor-element-6a7bb0c elementor-widget elementor-widget-heading"
                    data-element_type="widget" data-id="6a7bb0c"
                    data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Mission</h2></div>
                </div>
                <div
                    class="elementor-element elementor-element-50b4574 elementor-widget elementor-widget-text-editor"
                    data-element_type="widget" data-id="50b4574"
                    data-widget_type="text-editor.default">
                  <div class="elementor-widget-container">
                    <p style="color: white !important;" >Our mission is to drive the evolution of document management, promising unmatched
                      convenience, security, and intelligence.
                    </p>
                   </div>
                </div>
                <div
                    class="elementor-element elementor-element-9504bd6 elementor-widget elementor-widget-text-editor"
                    data-element_type="widget" data-id="9504bd6"
                    data-widget_type="text-editor.default">
                  <div class="elementor-widget-container">
                  </div>
                </div>

              </div>
            </div>
            <div class="wFullMedia" style="width: 100%">
              <div
                  class="elementor-element elementor-element-d2f053d elementor-widget elementor-widget-heading"
                  data-element_type="widget" data-id="d2f053d"
                  data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    Values</h2></div>
              </div>
              <div
                  class="elementor-element elementor-element-45c85bf elementor-widget elementor-widget-text-editor"
                  data-element_type="widget" data-id="45c85bf"
                  data-widget_type="text-editor.default" style="display:flex;">
                <div class="elementor-widget-container">
                  <p style="color: white !important;">Our Passion for Data</p>
                  <p style="color: white !important;">People-First</p>
                  <p style="color: white !important;">Sustainable Operations</p>
                </div>
                <div class="elementor-widget-container" style="margin: 0 30px">
                  <p style="color: white !important;">Continuous Improvement</p>
                  <p style="color: white !important;">Ethical Business Practices</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

</template>
<script setup>
</script>