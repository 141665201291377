<template>

      <div class="w-full grid grid-cols-12 gap-6 md:p-20 p-0">
        <div class=" col-span-12 text-center mb-10 rounded-lg relative overflow-hidden ">
          <h1>If You would like to join our team, please send your CV to <span><a style="color: #BE0000" href="mailto:info@eds-egypt.com" type="mail">info@eds-egypt.com</a></span></h1>
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Data Entry Specialist</p>
          </div>
          <img src="../../assets/data-entry.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Project Manger</p>
          </div>
          <img src="../../assets/project-manger.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Warehouse Manger</p>
          </div>
          <img src="../../assets/warehouse.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Software Developer</p>
          </div>
          <img src="../../assets/dev.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Accountant</p>
          </div>
          <img src="../../assets/cc.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>
        <div class="md:col-span-4 col-span-12 shadow-md rounded-lg relative overflow-hidden ">
          <div class="flex items-center justify-center h-full w-full absolute z-20">
            <p style="color: white; font-size: 24px ; font-weight: bold">Sales Representative</p>
          </div>
          <img src="../../assets/sales.jpg" class="w-full h-full object-cover blur-[8px]" alt="solution-image" />
        </div>

      </div>
</template>

<script>

export default {
  data:()=>({
    slideIndex: 1
  }),
  methods:{
    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("team-card");
      if (n > slides.length) { this.slideIndex = 1 }
      if (n < 1) { this.slideIndex = slides.length }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.opacity = "0";
      }
      slides[this.slideIndex-1].style.opacity = "1";
    },
    plusSlides(n) {
      this.showSlides(this.slideIndex += n);
    }
  },
  mounted() {
    let slideIndex= 1
    this.showSlides(slideIndex);
  }
}
</script>
<style scoped>

.s-section {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 0 !important;
}
.s-row {
  margin: 0 0 100px 0 ;
  display: flex;
  flex-wrap: wrap;
}
.s-column {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.s-card {
  width: 80%;
  height: 70%;
  margin-bottom: 80px;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, white 10%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.s-icon-wrapper {

  background-color: #BC0000;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
@media screen and (min-width: 768px) {
  .s-section {
    padding: 0 2em;
  }
  .s-column {
    flex: 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .s-section {
    padding: 1em 3em;
  }
  .s-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.nav-p {
  background-color: white;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menu {
  display: flex;
  margin-right: 4%;
}

.menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  position: relative;
  margin-left: 10px;
}

.menu a {
  text-decoration: none;
  color: black;
  padding: 10px 5px;
  display: block;
}

.menu a:hover {
  color: #BC0000;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  width: 300px;
  left: -99999px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;

}

.dropdown li {
  margin: 0;
}
.dropdown li a {
  color: #606060;
  padding: 15px;
}
.dropdown li :hover {
  color: white;
  background-color: #BC0000 !important;

}
.nav-item:hover .dropdown {
  display: block;
  left: 0;

}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu {
  display: none;
  background-color: white;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.mobile-menu ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.mobile-menu a {
  padding: 10px 20px;
  display: block;
  color: black;
  text-decoration: none;
}

.mobile-menu a:hover {
  color: white;
  background-color: #BC0000;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
  }
}

@media (min-width: 769px) {
  .mobile-menu {
    display: none;
  }
}

.sub-item {
  padding-left: 20px;
}


.s-section {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 0 !important;
}
.s-row {
  margin: 0 0 100px 0 ;
  display: flex;
  flex-wrap: wrap;
}
.s-column {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.s-card {
  width: 80%;
  height: 70%;
  margin-bottom: 80px;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, white 10%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.s-icon-wrapper {

  background-color: #BC0000;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
@media screen and (min-width: 768px) {
  .s-section {
    padding: 0 2em;
  }
  .s-column {
    flex: 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .s-section {
    padding: 1em 3em;
  }
  .s-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.nav-p {
  background-color: white;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menu {
  display: flex;
  margin-right: 4%;
}

.menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  position: relative;
  margin-left: 10px;
}

.menu a {
  text-decoration: none;
  color: black;
  padding: 10px 5px;
  display: block;
}

.menu a:hover {
  color: #BC0000;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  width: 300px;
  left: -99999px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;

}

.dropdown li {
  margin: 0;
}
.dropdown li a {
  color: #606060;
  padding: 15px;
}
.dropdown li :hover {
  color: white;
  background-color: #BC0000 !important;

}
.nav-item:hover .dropdown {
  display: block;
  left: 0;

}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu {
  display: none;
  background-color: white;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.mobile-menu ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.mobile-menu a {
  padding: 10px 20px;
  display: block;
  color: black;
  text-decoration: none;
}

.mobile-menu a:hover {
  color: white;
  background-color: #BC0000;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
  }
}

@media (min-width: 769px) {
  .mobile-menu {
    display: none;
  }
}

.sub-item {
  padding-left: 20px;
}

.join-our-team {
  text-align: center;
  padding: 20px;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.slideshow-container {
  position: relative;

  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 600px; /* Added height to container */
}

.team-card {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.team-card h3 {
  font-size: 1.5em;
  color: white;
  margin: 10px 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.active {
  opacity: 1;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}
</style>


