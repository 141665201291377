<template>
  <div>
    <app-header/>
    <page-content />
    <app-footer />
  </div>
</template>
<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";
import PageContent from "@/components/VaultRoom/PageContent.vue";
export default {
  components:{AppFooter,PageContent,AppHeader},
}
</script>